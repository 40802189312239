import { useState } from 'react';
import { toast } from 'react-toastify';
import useApi from '../../../../../utils/api';

const useTransferCoins = () => {
  const [transferCoinsLoading, setTransferCoinsLoading] = useState(false);
  const [closeTransferCoinsModal, setCloseTransferCoinsModal] = useState(false);
  const [transferCoinsData, setTransferCoinsData] = useState(null);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [errors, setErrors] = useState(null);

  const { authPost } = useApi();

  const onConfirmTransferCoins = async () => {
    try {
      setTransferCoinsLoading(true);

      const toUserId = transferCoinsData?.toUserId?.value?.SID;
      const amount = transferCoinsData?.amount;
      await authPost('/gamer-wallet/transfer-coins', { data: { toUserId, amount } });
      toast.success('Coins transferred successfully');
      setCloseTransferCoinsModal(true);
    } catch (e) {
      toast.error(e);
    }
    setTransferCoinsLoading(false);
    setTransferCoinsData(null);
    setOpenConfirmModal(false);
  };

  const onCloseTransferCoinsModal = () => {
    setErrors(null);
    setTransferCoinsData(null);
    setOpenConfirmModal(false);
  };

  const onTransferCoinsSubmit = async (data) => {
    const toUserId = data?.toUserId?.value?.SID;
    const amount = data?.amount;
    if (!toUserId && !amount) {
      setErrors({
        toUserId: 'Please select a user',
        amount: 'Please enter an amount',
      });
      return;
    }
    if (!toUserId) {
      setErrors({ toUserId: 'Please select a user' });
      return;
    }
    if (!amount) {
      setErrors({ amount: 'Please enter an amount' });
      return;
    }
    if (amount <= 0) {
      setErrors({ amount: 'Please enter a valid amount' });
      return;
    }

    setTransferCoinsData(data);
    setOpenConfirmModal(true);
  };

  return {
    coinTrasferErrors: errors,
    setCoinTrasferErrors: setErrors,
    openConfirmModal,
    setOpenConfirmModal,
    transferCoinsData,
    setTransferCoinsData,
    onConfirmTransferCoins,
    onCloseTransferCoinsModal,
    onTransferCoinsSubmit,
    transferCoinsLoading,
    setCloseTransferCoinsModal,
    closeTransferCoinsModal,
  };
};

export default useTransferCoins;

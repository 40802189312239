import { useNavigate } from 'react-router-dom';
import { Container, Grid, Typography } from '@mui/material';
import { useContext } from 'react';
import { LeaderboardContext, LeaderboardProvider } from './context';
import TopYesterday from './components/TopYesterday';
import TopAllTime from './components/TopAllTime';
import AllRank from './components/AllRank';

const LeaderboardPage = () => {
  const { user } = useContext(LeaderboardContext);
  const navigate = useNavigate();

  if (!user) {
    navigate('/landing');
  }

  return (
    <Container maxWidth="xl">
      <Grid xl={12} lg={12} md={12} xs={12} sm={12} item sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
        <h1
          className="primary-text-gradient"
          style={{
            fontWeight: 600,
            fontSize: '25px',
            textTransform: 'uppercase',
          }}
        >
          Gamer Leaderboard
        </h1>
      </Grid>

      <Grid item xl={12} lg={12} md={12} sm={12} xs={12} sx={{ mb: 4 }}>
        <div className="leaderboard-page">
          <Container className="flex-1 top-container">
            <Typography
              className="primary-text-gradient"
              sx={{
                fontSize: '19px', fontWeight: 'bold', mb: 2, display: 'flex', justifyContent: 'center',
              }}
            >
              Top Gamers Last 24 Hours
            </Typography>

            <TopYesterday />
          </Container>
          <Container className="flex-1 top-container">
            <Typography
              className="primary-text-gradient"
              sx={{
                fontSize: '19px', fontWeight: 'bold', mb: 2, display: 'flex', justifyContent: 'center',
              }}
            >
              Top 5 All time
            </Typography>

            <TopAllTime />
          </Container>
        </div>
        <div className="leaderboard-page" style={{ display: 'none' }}>
          <Container className="flex-1 top-container">
            <Typography sx={{
              color: 'var(--primary-color)', fontSize: '19px', fontWeight: 'bold', mb: 2, display: 'flex', justifyContent: 'center',
            }}
            >
              Top 25
            </Typography>

            <AllRank />
          </Container>
        </div>
      </Grid>
    </Container>
  );
};

export default () => (
  <LeaderboardProvider>
    <LeaderboardPage />
  </LeaderboardProvider>
);

import { useNavigate } from 'react-router-dom';
import { useContext, useState } from 'react';
import { Container, Typography } from '@mui/material';
import { FAQContext, FAQProvider } from './context';
import Layout from '../../../components/CardLayout';
import ComponentLoader from '../../../components/ComponentLoader';
import FAQAccordion from './components/FAQAccordion';

const FAQPage = () => {
  const {
    t,
    user,

    faqsData,
    faqsDataLoaded,
  } = useContext(FAQContext);
  const navigate = useNavigate();

  if (!user) {
    navigate('/landing');
  }

  return (
    <Container maxWidth="xl" className="faq-page" sx={{ paddingX: { xs: 0, lg: '16px' } }}>
      <Layout
        maxWidth="100%"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          mt: 6,
          gap: 8,
        }}
      >
        <Typography
          variant="h1"
          sx={{
            display: 'flex',
            justifyContent: 'center',
            fontSize: '26px',
            fontWeight: 600,
            mb: 8,
            width: '100%',
          }}
        >
          <span className="primary-text-gradient">{t('FAQs')}</span>
        </Typography>
        {!faqsDataLoaded && <ComponentLoader />}
        {(faqsDataLoaded && faqsData.length > 0) ? (
          faqsData.filter((faq) => faq.active).map((faq, idx) => (
            <FAQAccordion key={idx} faq={faq} />
          ))
        ) : (<Typography fontSize="16px" textAlign="center">Stay tunned.</Typography>)}
      </Layout>
    </Container>
  );
};

export default () => (
  <FAQProvider>
    <FAQPage />
  </FAQProvider>
);

import { useContext } from 'react';
import { Context } from '../../context';
import { EJTree, NonEjTree } from './components/ej';

export default function UsersTree() {
  const {
    user,
    passTypes,
    users,
    fetchSelectedUser,
    loadingUsersSponsored,
    usersSponsored,
    onlySponsorsToggle,
  } = useContext(Context);

  if (user.Username === 'ej') {
    return (
      <EJTree
        user={user}
        passTypes={passTypes}
        currentUser={user}
        users={users}
        fetchSelectedUser={fetchSelectedUser}
        loadingUsersSponsored={loadingUsersSponsored}
        usersSponsored={usersSponsored}
        onlySponsorsToggle={onlySponsorsToggle}
      />
    );
  }

  return (
    <NonEjTree
      user={user}
      passTypes={passTypes}
      users={users}
      currentUser={user}
      fetchSelectedUser={fetchSelectedUser}
      loadingUsersSponsored={loadingUsersSponsored}
      usersSponsored={usersSponsored}
      onlySponsorsToggle={onlySponsorsToggle}
    />
  );
}

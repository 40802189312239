/* eslint-disable react/no-danger */
/* eslint-disable react/jsx-props-no-spreading */
import { useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import {
  Container, Grid, Typography, 
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { AdminFAQContext, AdminFAQProvider } from './context';
import Layout from '../../../components/CardLayout';
import Button from '../../../components/Button';
import AddEditFAQModal from './components/AddEditFAQModal';
import DeleteFAQModal from './components/DeleteFAQModal';
import FAQCategoriesModal from './components/FAQCategoriesModal';
import DeleteFAQCategoryModal from './components/DeleteFAQCategoryModal';
import ComponentLoader from '../../../components/ComponentLoader';

const AdminFAQPage = () => {
  const {
    t,
    user,
    handleOpenModalToAdd,
    handleOpenModalToEdit,
    handleOpenDeleteModal,
    faqsData,
    faqsDataLoaded,
    setOpenFAQCategoryModal,
  } = useContext(AdminFAQContext);

  const navigate = useNavigate();

  if (!user) {
    navigate('/landing');
  }

  return (
    <Container maxWidth="xl" className="admin-faq-page">
      <Layout
        maxWidth="100%"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          mt: 6,
        }}
      >
        <Typography
          sx={{
            display: 'flex',
            justifyContent: 'center',
            fontSize: '26px',
            fontWeight: 600,
            mb: 1,
            width: '100%',
          }}
        >
          <span className="primary-text-gradient">{t('FAQ Administration')}</span>
        </Typography>
        <Grid
          item
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={12}
          sx={{
            display: 'flex', justifyContent: 'center', paddingTop: '14px', gap: '24px', 
          }}
        >
          <Button size="sm" onClick={handleOpenModalToAdd}>
            <span>{t('Add FAQ')}</span>
          </Button>
          <Button size="sm" onClick={() => setOpenFAQCategoryModal(true)}>
            <span>{t('FAQ Categories')}</span>
          </Button>
        </Grid>
        
        <AddEditFAQModal />
        <DeleteFAQModal />
        <FAQCategoriesModal />
        <DeleteFAQCategoryModal />

        {faqsDataLoaded ? (
          <Grid container className="faqs" paddingTop={4}>
            <Grid item xs={8} md={10}>
              <Typography fontSize="16px" fontWeight="600">
                FAQs
              </Typography>
            </Grid>
            <Grid item xs={4} md={2}>
              <Typography fontSize="16px" fontWeight="600">
                Actions
              </Typography>
            </Grid>
            {faqsData.length > 0 && faqsData.map((faq, idx) => (
              <Grid container key={idx}>
                <Grid item xs={12}>
                  <div className="divider" />
                </Grid>
                <Grid item xs={8} md={10} display="flex" flexDirection="column" gap={1}>
                  <Typography fontSize="14px" paddingLeft={1}>
                    <span style={{ fontWeight: 600 }}>Question: </span>
                    {faq.question}
                  </Typography>
                  <Typography fontSize="14px" paddingLeft={1}>
                    <span style={{ fontWeight: 600 }}>Category: </span>
                    {faq.category}
                  </Typography>
                  <Typography fontSize="14px" paddingLeft={1}>
                    <span style={{ fontWeight: 600 }}>Status: </span>
                    {faq.active ? 'Active' : 'Inactive'}
                  </Typography>
                  <Typography fontSize="14px" paddingLeft={1}>
                    <span style={{ fontWeight: 600 }}>Answer: </span>
                  </Typography>
                  <Grid item paddingLeft={1}>
                    <span dangerouslySetInnerHTML={{ __html: faq.answer }} />
                  </Grid>
                </Grid>
                <Grid item xs={4} md={2} display="flex" gap={2}>
                  <EditIcon
                    sx={{ cursor: 'pointer', display: 'block' }}
                    color="primary"
                    onClick={() => handleOpenModalToEdit(faq)}
                  />
                  <DeleteIcon
                    sx={{ cursor: 'pointer', display: 'block' }}
                    color="primary"
                    onClick={() => handleOpenDeleteModal(faq)}
                  />
                </Grid>
              </Grid>
            ))}
          </Grid>
        ) : (
          <ComponentLoader />
        )}
      </Layout>
    </Container>
  );
};

export default () => (
  <AdminFAQProvider>
    <AdminFAQPage />
  </AdminFAQProvider>
);

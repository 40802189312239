import React from 'react';
import LoopIcon from '@mui/icons-material/Loop';

/**
 * AppLoader component
 * @returns {ReactNode}  AppLoader component
 */
export default function ComponentLoader() {
  return (
    <div className="component-loader">
      <div className="loading-div">
        <span className="loading-text">LOADING ...</span>
        <LoopIcon color="primary" className="loading-icon" />
      </div>
    </div>
  );
}

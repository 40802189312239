const hook = {
  onTransferCoinsSubmit: () => { },
  transferCoinsLoading: false,
  setCloseTransferCoinsModal: () => { },
  closeTransferCoinsModal: false,
  openConfirmModal: false,
  setOpenConfirmModal: () => { },
  transferCoinsData: null,
  setTransferCoinsData: () => { },
  onConfirmTransferCoins: () => { },
  onCloseTransferCoinsModal: () => { },
  coinTrasferErrors: null,
  setCoinTrasferErrors: () => { },
};

export default hook;

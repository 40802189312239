/* eslint-disable indent */
import { useContext, useEffect, useState } from 'react';
import {
  Container, Grid, Slider, Typography,
} from '@mui/material';
import Modal from '../../../components/Modal';
import { Context } from '../context';
import { modalTypes } from '../config';
import Button from '../../../components/Button';
import RadioGroup from '../../../components/Inputs/RadioGroup';
import { AppContext } from '../../../utils/store/app';
import { renderNumber } from '../../../utils/string';
import { getNextWeekFriday, dateIsBusinessDay } from '../../../utils/date';

const withdrawIncrement = 135;

export default function WithdrawModal() {
  const {
    t,
    modalData,
    onModalClose,
    onWithdrawSubmit,
    defaultWallet,
    solanaWallet,
  } = useContext(Context);

  const { passTypes } = useContext(AppContext);

  const filteredPassTypes = [
    ...passTypes.filter(
      (p) => ((p.price > 0 && p.price % withdrawIncrement === 0)
        || (p.name?.includes('Rank Up Challenge')))
        && p.active,
    ),
  ];

  const [withdrawCharge, setWithdrawCharge] = useState(0);
  const [minWithdrawAmount, setMinWithdrawAmount] = useState(withdrawIncrement);
  const [withdrawAmount, setWithdrawAmount] = useState(withdrawIncrement);
  const [withdrawType, setWithdrawType] = useState('passes');

  const [selectedPassTypeId, setSelectedPassTypeId] = useState(null);
  const [selectedIsBundle, setSelectedIsBundle] = useState(false);
  const [openConfirmSubmitModal, setOpenConfirmSubmitModal] = useState(false);
  const [todayIsWeekend, setTodayIsWeekend] = useState(false);

  const handleWithdrawButtonSubmit = () => {
    const passTypeId = selectedPassTypeId || filteredPassTypes[0].id;
    const selectedPassType = filteredPassTypes.find((pt) => pt.id === passTypeId);

    onWithdrawSubmit({
      withdrawType,
      withdrawAmount,
      gamePass: selectedPassType,
    });

    onModalClose();
    setOpenConfirmSubmitModal(false);
  };

  const handleSelectedPassTypeChange = (id) => {
    setSelectedPassTypeId(id);

    const selected = filteredPassTypes.find((pt) => pt.id === id);

    const newPrice = selected?.price || selected?.Price || 0;

    setMinWithdrawAmount(newPrice);
    setWithdrawAmount(newPrice > defaultWallet?.balance ? withdrawIncrement : newPrice);

    setSelectedIsBundle(selected?.Price > 0);
  };

  const availableBalance = defaultWallet.balance - withdrawCharge;

  const maxWithdrawAmount = (Math.floor(availableBalance / minWithdrawAmount) * minWithdrawAmount);

  const getMarks = [
    {
      value: minWithdrawAmount,
      label: `$${renderNumber(minWithdrawAmount)}`,
    },
    {
      value: minWithdrawAmount * ((maxWithdrawAmount / minWithdrawAmount) < 6 ? 2 : 1),
      label: `$${renderNumber(minWithdrawAmount * ((maxWithdrawAmount / minWithdrawAmount) < 6 ? 2 : 1))}`,
    },
    {
      value: Math.ceil((maxWithdrawAmount / minWithdrawAmount) / 2) * minWithdrawAmount,
      label: `$${renderNumber(Math.ceil((maxWithdrawAmount / minWithdrawAmount) / 2) * minWithdrawAmount)}`,
    },
    {
      value: maxWithdrawAmount,
      label: `$${renderNumber(maxWithdrawAmount)}`,
    },
  ];

  const hideSlider = maxWithdrawAmount <= minWithdrawAmount || selectedIsBundle || (todayIsWeekend && withdrawType === 'solana');

  useEffect(() => {
    setMinWithdrawAmount(withdrawIncrement);
    setWithdrawAmount(withdrawIncrement);
    setSelectedPassTypeId(filteredPassTypes[0]?.id);
    setSelectedIsBundle(false);

    // Charge only for withdrawals
    setWithdrawCharge(withdrawType === 'solana' ? 5 : 0);
  }, [withdrawType]);

  useEffect(() => {
    setTodayIsWeekend(!dateIsBusinessDay());
  }, []);

  if (modalData?.type === modalTypes.WITHDRAW) {
    return (
      <>
        <Modal
          width="650px"
          title={modalData.description}
          open={!!modalData}
          handleClose={onModalClose}
        >
          <Typography
            className="text-center"
            sx={{ fontSize: '18px', fontWeight: 600, marginBottom: 2 }}
          >
            Redeem can only be performed
            {' '}
            <br />
            {`in $${withdrawIncrement} increments`}
          </Typography>

          {!!defaultWallet && (availableBalance + withdrawCharge) >= withdrawIncrement ? (
            <Container>
              <RadioGroup
                sx={{
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  color: '#FFFFFF',
                }}
                onChange={(e) => {
                  setWithdrawType(e);
                }}
                value={withdrawType}
                setErrors={() => { }}
                name="withdrawType"
                options={[
                  { label: 'To Solana Wallet', value: 'solana' },
                  { label: 'Redeem for Coin Packs', value: 'passes' },
                ]}
              />

              {withdrawType === 'solana'
                && (!solanaWallet ? (
                  <Typography className="text-center">
                    You need to connect your Solana Wallet to the system
                  </Typography>
                ) : (
                  todayIsWeekend ? (
                    <p className="mt-5 mb-10 text-center">
                      Redemptions are only allowed from Monday to Friday.
                    </p>
                  ) : (
                    <>
                      <Typography className="text-center">
                        {`Transferring $${withdrawAmount} to your solana Wallet`}
                      </Typography>
                      <Typography className="text-center" style={{ color: 'grey', wordBreak: 'break-all' }}>
                        {` ${solanaWallet.publicKey}`}
                      </Typography>
                      <Typography className="text-center" style={{ paddingTop: '4px', color: 'var(--primary-color)' }}>
                        A $5 service charge will be applied to this transaction.
                      </Typography>
                    </>
                  )
                ))}

              {withdrawType === 'passes' && (
                <RadioGroup
                  onChange={(e) => {
                    handleSelectedPassTypeChange(e);
                  }}
                  value={selectedPassTypeId}
                  defaultValue={selectedPassTypeId || filteredPassTypes[0].id}
                  setErrors={() => { }}
                  name="selectedPassTypeId"
                  options={filteredPassTypes.map((p) => ({
                    label: `${p.name} ($${p?.price || p?.Price}) ${p?.price
                      ? `(${Math.floor(withdrawAmount / p.price)} packs)`
                      : ''
                      }`,
                    value: p.id,
                  }))}
                />
              )}

              <Grid sx={{ marginTop: 2 }}>
                <Grid sx={{ paddingX: '10px', display: hideSlider ? 'none' : 'block' }}>
                  <Slider
                    min={minWithdrawAmount}
                    max={maxWithdrawAmount}
                    value={withdrawAmount}
                    step={minWithdrawAmount}
                    getAriaValueText={() => `$${withdrawAmount}`}
                    valueLabelDisplay="auto"
                    onChange={(e, v) => {
                      setWithdrawAmount(v);
                    }}
                    disabled={hideSlider}
                    marks={getMarks}
                  />
                </Grid>
                <Button
                  onClick={() => {
                    if ((availableBalance >= minWithdrawAmount) && !(todayIsWeekend && withdrawType === 'solana')) {
                      setOpenConfirmSubmitModal(true);
                    }
                  }}
                  className="mt-6"
                  disabled={(availableBalance < minWithdrawAmount) || (todayIsWeekend && withdrawType === 'solana')}
                >
                  <span className="no-wrap">
                    {availableBalance >= minWithdrawAmount
                      ? `Redeem (${renderNumber(withdrawAmount + withdrawCharge)})`
                      : 'Redeem'}
                  </span>
                </Button>

                {withdrawType === 'solana' && (
                  <Typography
                    textAlign="center"
                    sx={{
                      fontSize: { xs: '14px', md: '16px' },
                      paddingTop: { xs: 2, md: 3 },
                    }}
                  >
                    {`The Friday of the week following your redemption: ${getNextWeekFriday()}`}
                  </Typography>
                )}
              </Grid>
            </Container>
          ) : (
            <Typography
              className="text-center"
              sx={{ marginTop: 2, marginBottom: 3 }}
            >
              {`Your current balance is $${renderNumber(availableBalance || 0)}`}
            </Typography>
          )}
        </Modal>
        {openConfirmSubmitModal && (
          <Modal
            open={openConfirmSubmitModal}
            title="Are your Sure?"
            handleClose={() => setOpenConfirmSubmitModal()}
            width="550px"
          >
            <Typography
              fontSize="16px"
              fontWeight={500}
              textAlign="center"
              marginBottom="40px"
              display="flex"
              flexDirection="column"
            >
              <span>
                {`Do you want to Redeem $${renderNumber(withdrawAmount + withdrawCharge)}
                  ${withdrawType === 'solana' ? 'to your Solana Wallet' : ' from your Wallet'}?
                `}
              </span>
              <span>
                {withdrawCharge > 0 
                  ? `A $${withdrawCharge} service charge for this transaction`
                  : ''}
              </span>
            </Typography>
            <Grid
              item
              xs={12}
              display="flex"
              sx={{ 
                justifyContent: { xs: 'center', md: 'flex-end' },
                flexDirection: { xs: 'column-reverse', md: 'row' },
                alignItems: 'center',
                gap: { xs: '16px', md: '20px' },
              }}
            >
              <Button
                onClick={() => setOpenConfirmSubmitModal(false)}
                size="sm"
                type="button"
                bordered
                style={{ width: '100%', padding: '12px', fontSize: '14px' }}
              >
                {t('No')}
              </Button>
              <Button
                onClick={() => {
                  if (availableBalance >= minWithdrawAmount) {
                    handleWithdrawButtonSubmit();
                  }
                }}
                size="sm"
                type="button"
                style={{ width: '100%', padding: '12px', fontSize: '14px' }}
              >
                {t('Yes')}
              </Button>
            </Grid>
          </Modal>
        )}
      </>
    );
  }
}

/* eslint-disable jsx-a11y/control-has-associated-label */
import { Container, Grid, Typography } from '@mui/material';
import Twitter from '@mui/icons-material/Twitter';
import Facebook from '@mui/icons-material/Facebook';
// import Instagram from '@mui/icons-material/Instagram';
import copy from 'copy-to-clipboard';
import { useContext, useMemo } from 'react';
import { toast } from 'react-toastify';
import TextInput from '../../../components/Inputs/TextInput';
import { Context } from '../context';
import Button from '../../../components/Button';
// import Banner from '../../../components/Banner';
// import { PROMO_MESSAGES } from '../../../constants';
import ProgressBar from '../../../components/ProgressBar';

export default function ReferalLink() {
  const { user, qualificationPercent } = useContext(Context);

  // const shareInstagramLink = useMemo(() => 'https://www.instagram.com/direct/inbox', []);
  const referalLink = useMemo(() => `${user.Username}.${process.env.REACT_APP_BASE_DOMAIN}`, [user]);
  const shareTwitterLink = useMemo(() => `https://x.com/intent/tweet?url=https://${referalLink}&text=${'Join me on MemeGames.ai, the ultimate Memecoin competition!'}`, [referalLink]);
  const shareFacebookLink = useMemo(() => `https://www.facebook.com/share_channel/?link=https://${referalLink}&source_surface=external_reshare&display=popup&hashtag`, [referalLink]);

  return (
    <>
      {/* <Banner data={PROMO_MESSAGES.mvpKickoff} /> */}
      <Container sx={{
        display: 'flex', justifyContent: 'center', mb: 10, flexDirection: 'column',
      }}
      >
        <Typography variant="h5" sx={{ fontWeight: 600, mx: 'auto' }}>
          {`${user.Name || ''} ${user.Surname || ''}`}
        </Typography>
        <Typography sx={{
          color: 'var(--primary-color)', textTransform: 'uppercase', fontSize: '15px', mx: 'auto',
        }}
        >
          {user.promoBonusActive ? user.promoRankName : user.rank?.name || ''}          
          {' '}
          {/* {user.qualified ? user?.rank?.name : ''}
          {' '}
          <span style={{ color: 'gray', marginLeft: '4px' }}>
            {user.qualified ? '' : (
              <span className="flex flex-column justify-center flex-1 mr-2 " style={{ width: '183px' }}>
                <Typography sx={{ fontSize: '13px', textWrap: 'nowrap', mb: 0.5 }} variant="span">Qualification progress</Typography>
                <ProgressBar variant="determinate" value={qualificationPercent?.progress || 0} />
              </span>
            )}
          </span> */}
          <span style={{ color: 'gray', marginLeft: '4px' }}>
            {user.Active ? '' : 'Inactive'}
          </span>
        </Typography>
      </Container>
      <Grid container alignItems="center" spacing={1}>
        <Grid item xl={3} lg={3} md={3} sm={12} xs={12}>
          <Typography sx={{ py: 'auto', my: 'auto', fontWeight: 500 }}>Ambassador Link:</Typography>
        </Grid>
        <Grid item xl={9} lg={9} md={9} sm={12} xs={12}>
          <TextInput
            readOnly
            size="sm"
            value={`https://${referalLink}`}
            endAdornment={(
              <div className="p-1 flex">
                <Button
                  onClick={() => {
                    toast.success('Link Copied!');
                    copy(`https://${referalLink}`);
                  }}
                  className="py-auto my-auto mr-1"
                  bordered
                  size="sm"
                >
                  <span className="no-wrap">COPY LINK</span>
                </Button>
                <div className="flex flex-column justify-center">
                  <div className="flex ml-1 mt-1">
                    <a className="mr-1 cursor-pointer" href={shareTwitterLink} target="_blank" rel="noreferrer">
                      <Twitter sx={{ fontSize: 20 }} color="primary" />
                    </a>
                    {/* <a 
                    className="mr-1 
                    cursor-pointer" href={shareInstagramLink} target="_blank" rel="noreferrer">
                      <Instagram sx={{ fontSize: 20 }} color="primary" />
                    </a> */}
                    <a className="mr-1 cursor-pointer" href={shareFacebookLink} target="_blank" rel="noreferrer">
                      <Facebook sx={{ fontSize: 20 }} color="primary" />
                    </a>
                  </div>
                </div>
              </div>
            )}
          />
        </Grid>
      </Grid>
    </>
  );
}

import { Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import Layout from '../../../../components/CardLayout';

export default function PassTypeCard({ data, onPassTypeClick = () => { } }) {
  return (
    <Layout>
      <Typography
        sx={{
          fontWeight: 600,
          fontSize: '16px',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <span className="primary-text-gradient">{data.name}</span>
        <EditIcon
          sx={{ cursor: 'pointer' }}
          color="primary"
          onClick={() => {
            onPassTypeClick(data);
          }}
        />
      </Typography>
      <div className="divider" />
      <Typography sx={{ opacity: 0.5 }}>{data.description}</Typography>
      <Typography>
        Price :
        <span className="pl-2">
          $
          {Number(data.price || 0).toLocaleString('en-us')}
        </span>
      </Typography>
      <Typography>
        Available Days :
        <span className="pl-2">{data.days}</span>
      </Typography>
      <Typography>
        Coins :
        <span className="pl-2">{`${data.points ? data.points : 0}`}</span>
      </Typography>
      <Typography>
        Show on signup :
        <span className="pl-2">{`${data.useOnSignUp ? 'Yes' : 'No'}`}</span>
      </Typography>
      <Typography>
        Calculate as PV :
        <span className="pl-2">{`${data.calculateOnPv ? 'Yes' : 'No'}`}</span>
      </Typography>
      <Typography>
        Transferable :
        <span className="pl-2">{`${data.transferable ? 'Yes' : 'No'}`}</span>
      </Typography>
      <Typography>
        Activate Immediately :
        <span className="pl-2">{`${data.activateImmediately ? 'Yes' : 'No'}`}</span>
      </Typography>
      <Typography>
        Purchase with :
        <span className="pl-2">{`${data.purchaseWith}`}</span>
      </Typography>

      {data.addTeamBonus && (
        <Typography>
          Team Bonus Amount :
          <span className="pl-2">
            $
            {Number(data.teamBonusAmount || 0).toLocaleString('en-us')}
          </span>
        </Typography>
      )}
      <Typography
        sx={{
          color: data.active ? 'green' : 'red',
          position: 'absolute',
          bottom: '10px',
          right: '10px',
        }}
      >
        <span className="pl-2">{`${data.active ? 'Active' : 'Inactive'}`}</span>
      </Typography>
    </Layout>
  );
}

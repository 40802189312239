import { useContext } from 'react';
import OthersCont from '../OthersCont';
import { Context } from '../../../context';

export default function YesterdayOtherCoins({
  game = {},
  coins,
  isMobile,
  user,
  currentVoteIndex,
  setCurrentVoteIndex,
}) {
  const votes = game?.votes || {};
  const { chosenYesterdayMemeCoin: chosenYesterdayMemeCoins } = useContext(Context);
  const chosenMemeCoin = chosenYesterdayMemeCoins.find((c) => c.gameId === game.scheduleGameId?.id);
  const isTop10 = game?.rankingPercentage <= 10;
  const potentialPoints = game?.score || 0;
  const percentageChange = game?.change || 0;
  const leaderboardRank = game?.rankingPercentage || 0;

  return (
    <OthersCont
      user={user}
      game={game}
      votes={votes}
      leader={game?.scheduleGameId?.leader}
      isYesterday
      coins={coins}
      isTop10={isTop10}
      chosenMemeCoin={chosenMemeCoin}
      leaderboardRank={leaderboardRank}
      potentialPoints={potentialPoints}
      percentageChange={percentageChange}
      isMobile={isMobile}
      yesterdayChartData={game?.chartData}
      yesterdayChartLegend={game?.chartLegend}
      currentVoteIndex={currentVoteIndex}
      setCurrentVoteIndex={setCurrentVoteIndex}
    />
  );
}

/* eslint-disable react/no-danger */
import { useState } from 'react';
import { Grid } from '@mui/material';
import CustomAccordion from '../../../../components/Accordion';

const FAQAccordion = ({ faq }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <CustomAccordion
      _id={faq.id}
      className="faq-accordion"
      expanded={isOpen}
      setExpanded={() => setIsOpen((prev) => !prev)}
      summary={(
        <p
          className="primary-text-gradient"
          style={{ fontSize: '16px', fontWeight: 600 }}
        >
          {faq.question}
        </p>
      )}
      details={(
        <Grid container display="flex" flexWrap="wrap" flexDirection="column">
          <span dangerouslySetInnerHTML={{ __html: faq.answer }} />
          <div className="flex pt-2">
            <span
              style={{
                fontSize: '12px',
                background: 'var(--primary-color)',
                padding: '2px 8px',
                borderRadius: '6px',
                color: 'black',
                fontWeight: 600,
              }}
            >
              {faq.category}
            </span>
          </div>
        </Grid>
      )}
    />
  );
};

export default FAQAccordion;

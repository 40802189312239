import { renderNumber } from '../../../utils/string';

const defaultColConfig = {
  sortable: false,
  headerClassName: 'super-app-theme--header',
  headerAlign: 'left',
  cellClassName: (params) => {
    if (params.field === 'rank') {
      return 'first-column';
    }
    return '';
  },
};

export const memegamerChallengeColumnsConfig = (t) => [
  {
    field: 'SID',
    headerName: t('SID'),
    minWidth: 120,
    ...defaultColConfig,
  },
  {
    field: 'Username',
    headerName: t('Username'),
    minWidth: 200,
    ...defaultColConfig,
  },
  {
    field: 'Package',
    headerName: t('Package'),
    flex: 1,
    minWidth: 200,
    ...defaultColConfig,
  },
  {
    field: 'EnrolledParentID',
    headerName: t('Sponsor ID'),
    minWidth: 120,
    ...defaultColConfig,
  },
  {
    field: 'SponsorUsername',
    headerName: t('Sponsor Username'),
    minWidth: 200,
    ...defaultColConfig,
  },
  {
    field: 'SponsorPackage',
    headerName: t('Sponsor Package'),
    flex: 1,
    minWidth: 200,
    ...defaultColConfig,
  },
];

export default {};

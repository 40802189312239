export const bannerFormConfig = [
  {
    name: 'title',
    type: 'text',
    label: 'Title',
    placeholder: 'Banner title',
    required: true,
  },
  {
    name: 'startDate',
    type: 'date',
    label: 'Start Date',
    required: true,
  },
  {
    name: 'endDate',
    type: 'date',
    label: 'End Date',
    required: true,
  },
  {
    name: 'message',
    type: 'textarea',
    label: 'Message',
    placeholder: 'Type your message',
    required: true,
    rows: 6,
  },
];

export const bannerFormValidationConfig = {
  title: { isEmpty: false, type: 'String', message: 'Title is required' },
  message: { isEmpty: false, type: 'String', message: 'Message is required' },
  startDate: { isEmpty: false, type: 'Date', message: 'Start Date is required' },
  endDate: { isEmpty: false, type: 'Date', message: 'End Date is required' },
};

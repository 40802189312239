import dayjs from 'dayjs';

// 0 is Sunday and 6 is Saturday
const isBusinessDayIndex = (weekDay) => weekDay > 0 && weekDay < 6;

export const dateAfterBusinessDays = (days, formatted = true) => {
  let date = dayjs().tz('GMT');
  let businessDays = days;

  // The initial date must always be a business day
  // If the date is Sunday, move 1 day
  if (date.day() === 0) {
    date = date.add(1, 'day');
  }

  // If the date is Saturday, move 2 days
  if (date.day() === 6) {
    date = date.add(2, 'day');
  }

  while (businessDays > 0) {
    date = date.add(1, 'day');

    if (isBusinessDayIndex(date.day())) {
      businessDays -= 1;
    }
  }

  if (formatted) return date.format('DD MMM YYYY');

  return date.toDate();
};

// Check if the date is a Business Day
export const dateIsBusinessDay = (date = new Date()) => isBusinessDayIndex(dayjs(date).tz('GMT').day());

export const getNextWeekFriday = (startDate = new Date(), formatted = true) => {
  let date = dayjs(startDate).tz('GMT');

  // Get the days to the next Sunday (0)
  const currentDayIndex = date.day();
  const daysToNextMonday = 7 - currentDayIndex;

  // Add the days to the next Sunday + (5) to the next Friday
  date = date.add(daysToNextMonday + 5, 'day');

  if (formatted) return date.format('DD MMM YYYY');

  return date.toDate();
};

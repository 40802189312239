import { createContext } from 'react';
import useWallets from './hook';
import transferCoinsContext from '../components/TransferCoinsModal/context';

export const Context = createContext({
  t: () => {},
  wallets: [],
  loadingHistory: false,
  columns: [],
  historyData: [],
  totalPages: 0,
  historyPage: 0,
  latestRedeemData: [],
  fetchHistory: () => {},
  modalData: null,
  onTransferClick: () => {},
  onTransferSubmit: () => {},
  onModalClose: () => {},
  defaultWallet: 0,
  setDefaultWallet: () => {},
  coopWallet: 0,
  setCoopWallet: () => {},
  setModalData: () => {},
  confirmationModal: null,
  setConfirmationModal: () => {},
  onActionConfirm: () => {},
  loadingModal: false,
  setLoadingModal: () => {},
  errors: null,
  setErrors: () => {},
  commission: 10,
  setCommission: () => {},
  onCommitmentSave: () => {},
  onWithdrawSubmit: () => {},

  onBtcWalletExit: () => {},
  onBtcWalletClick: () => {},
  btcwalletModal: false,
  btcWalletNumber: 0,
  onChangeBtcWalletNumber: () => {},
  user: {},
  transferLoading: false,
  handleTabTransfersChange: () => {},
  transferTabsValue: 0,
  columnsTransfers: [],
  totalTransfersPages: 0,
  transfersData: [],
  transferesPage: 0,
  loadingTransfersTable: false,
  getTransfersHistory: () => {},
  onRedeemClick: () => {},

  onSaveSolanaWallet: () => {},
  onDeleteSolanaWallet: () => {},
  solanaWallet: null,
  defaultWalletLoaded: false,
  solanaTokensData: [],
  solanaTokensDataLoaded: false,
  solanaBalance: 0,
  setSolanaWallet: () => {},
  tokens: 0,
  ...transferCoinsContext,
});

export const Provider = ({ children, isGamer }) => {
  const value = useWallets({ isGamer });
  return <Context.Provider value={value}>{children}</Context.Provider>;
};

import { createContext } from 'react';
import usePendingRedeemApi from './api';

export const PendingRedemptionsContext = createContext({
  t: () => {},
  user: {},

  fetchRedeemData: () => {},
  redemptionsDataColumnsConfiguration: [],
  redemptionsData: [],
  loadedRedemptionsData: false,

  redeemDestinationData: {},
  submittingRedeemModal: false,
  setRedeemDestinationData: () => {},
  handlePayRedeemSubmitModal: () => {},
  handlePayRedeemCloseModal: () => {},
});

export const PendingRedemptionsProvider = ({ children }) => {
  const value = usePendingRedeemApi();

  return (
    <PendingRedemptionsContext.Provider value={value}>
      {children}
    </PendingRedemptionsContext.Provider>
  );
};

/* eslint-disable max-len */
import { Container, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

export default function Rules() {
  const [t] = useTranslation();
  return (
    <Container maxWidth="md" className="memegames-rules-page">
      <Typography
        sx={{
          fontSize: '20px',
          fontWeight: 'bold',
          display: 'flex',
          justifyContent: 'center',
          mt: 3,
        }}
      >
        <span className="primary-text-gradient">MEMEGAMES RULES</span>
      </Typography>

      <Typography sx={{ my: 3 }}>

        <b>
          {t('1. The Game.')}
          {' '}
        </b>
        {t('Our games of skill provides gamers with the opportunity to learn about the memecoin market through exciting and educational daily competitions. When you join MemeGames and compete in one of our three daily games, your job is to analyze the two trending publicly traded memecoins featured in that game, pick the coin you believe will perform better that day, and time your entry and exit from the game just right to achieve the highest percentage gain. The more skilled you are at analyzing the market, the better you will perform')}
      </Typography>

      <Typography sx={{ my: 3 }}>

        <b>
          {t('2. There\'s No Risk.')}
        </b>
        {' '}
        {t('Don’t worry if you pick the wrong memecoin, because our games are risk-free. MemeGames provides the ability to “play” the memecoin market without putting your hard-earned money at risk. While you’ll be analyzing and choosing between two publicly traded memecoins every time you play, no actual memecoins, cryptocurrencies, or securities of any type are purchased or sold through our games. You cannot make actual investments on MemeGames. Likewise, MemeGames does not provide investment advice and the inclusion of a memecoin in one of our games is not intended to be, and should not be construed as, a recommendation to buy that memecoin. If you want to make actual investments, do your own research and talk to a licensed financial advisor first. Although we hope MemeGames will help you learn about the memecoin market, whatever you do, don’t base your actual investment decisions on our games. That’s because our games are intended for entertainment and educational purposes only.')}
      </Typography>

      <Grid sx={{ my: 3 }}>
        <Typography>
          <b>
            {' '}
            {t('3. Play for Free. ')}
          </b>
          {t('You are welcome to play for free!')}
        </Typography>
        <ul
          style={{
            marginLeft: '40px',
            color: 'white',
            listStyleType: 'circle',
          }}
        >
          <li>
            {t('All new gamers are eligible for a free trial when they sign up with MemeGames, which includes a no cost, no obligation award of 100 MG Coins.')}
          </li>

          <li>
            {t('As the entry fees for our games start at 10 MG Coins, all gamers are able to play at least one of our games every month at no cost and with no obligations whatsoever.')}
          </li>

          {/* <li>
            {t('As choosing the right meme coin in a game may reward an additional 10 Coins, it’s possible to earn more coins and compete in higher level games without buying a gamer pack.')}
          </li> */}
        </ul>
      </Grid>

      <Grid sx={{ my: 3 }}>
        <b>
          {t(' 4.Gaming Packs.')}
          {' '}
        </b>
        {' '}
        {t('Other than by using our no-cost options, you can participate in any of our three daily games by purchasing one or more gamer packs.')}

        <ul
          style={{
            marginLeft: '40px',
            color: 'white',
            listStyleType: 'circle',
          }}
        >
          <li>
            {t('Packs can be purchased for $135, and we accept Bitcoin, Solana, ETH, and many other cryptocurrencies as payment. We do also accept credit cards with some KYC authorization through a third party required by the gamer.')}
          </li>

          <li>
            {t('When you purchase a $135 starter pack, you are awarded 135 MG Coins.')}
          </li>

          <li>
            {t('MG Coins must be used within 30 days of purchase, at which point they will expire. Your wallet is set up to hold multiple gaming packs at once, so you don’t have to wait until your MG Coin run out to stock up. You can purchase additional gamer packs in any quantity.')}
          </li>

          <li>
            {t('You may transfer coin packs to other gamers if you wish, using their email or username. You may select these coin packs to activate immediately upon receipt, and you can also set them to be non-transferrable if you wish.')}
          </li>

          <li>
            {t('The link to the Coin Packs page can be found here: ')}
            <a href="https://memegames.ai/packages">https://memegames.ai/packages</a>
          </li>
        </ul>
      </Grid>

      <Grid sx={{ my: 3 }}>

        <b>
          {t('  5. MG Coins.')}
          {' '}
        </b>
        <ul
          style={{
            marginLeft: '40px',
            color: 'white',
            listStyleType: 'circle',
          }}
        >
          <li>
            {t('Our games are played with MG Coins. As the exclusive gaming currency of MemeGames, however, MG Coins are not publicly traded or connected to a decentralized ledger. As they only can be used to play our games of skill, they have no economic value.')}
          </li>
        </ul>
      </Grid>

      <Grid sx={{ my: 3 }}>
        <b>
          {t(' 6. Daily Games.')}
          {' '}
        </b>
        <ul
          style={{
            marginLeft: '40px',
            color: 'white',
            listStyleType: 'circle',
          }}
        >
          <li>
            {t('Every 8 hours, MemeGames a game of skill available to gamers with a range of entry fees and start times.')}
          </li>

          <li>
            {t('Games will be available on a daily basis for 10, 25, and 100 MG Coins. You can play in any amount of games on any day.')}
          </li>

          <li>
            {t('Each game lasts for 24 hours.')}
          </li>

          <li>
            {t('Games are scheduled to begin every eight hours, and last for 24 hours each.')}
          </li>
        </ul>
      </Grid>

      <Grid sx={{ my: 3 }}>
        <b>{t('7. How to Play the Game.')}</b>
        <ul
          style={{
            marginLeft: '40px',
            color: 'white',
            listStyleType: 'circle',
          }}
        >
          <li>
            {' '}
            {t('Each game begins with the reveal of the two memecoins that will go head-to-head in that game.')}
            {' '}
          </li>

          <li>
            {t('The coins are selected by our proprietary AI Game Picker to ensure that the two memecoin options available in every game are “respected” coins, and have been trending and among the top performing memecoins during the hour immediately preceding the start of the game in which they will be featured.')}
          </li>

          <li>
            {t('When we reveal a game’s memecoins, we also will provide gamers with historical information, including price and volume data, about each coin to get you started with your analysis of that game’s memecoins. We obtain all pricing data for the memecoins used in our games from CoinGecko’s live exchange rates, and CoinGecko’s rates are determinative of any questions that may arise regarding the price of a memecoin included in one of our games.')}
          </li>

          <li>
            {t('Because these are games of skill, we encourage you to research the two available options before selecting your memecoin so that you can maximize the likelihood that you (i) pick the winning coin and (ii) time your entry and exit from the game in order to achieve your pick’s largest possible percentage price increase.')}
            {' '}
            <ul style={{
              marginLeft: '40px',
              color: 'white',
              listStyleType: 'square',
            }}
            >
              <li>
                {t('For example, if the price of a 100 MG Coin game’s winning memecoin is $0.10 at the start of the game and is $0.20 when the game ends, all of the gamers who enter their pick as soon as the game starts and decide not to exit the game before it ends will end the game with a 100% increase.')}
              </li>
              <li>
                {t('But that doesn’t meant those gamers will finish in the top 10% for that game. If the price of the winning memecoin initially falls to a low for the day of $0.05, later reaches a high for the day of $0.25, and is at $0.20 when the game ends, gamers who timed their entry at or near coin’s lowest price and their exit at or near its highest price that day will, of course, have larger percentage increases than gamers who enter when the game starts and remain in the game until it ends.')}
              </li>
            </ul>
          </li>

          <li>
            {t('When you determine the time is right to enter the game, remit your entry fee and select the memecoin you believe will have the largest percentage price increase over the game’s 24-hour runtime. We record the official price of your selected memecoin at the moment you make your selection—this is the opening price used to calculate your percentage change at the end of the game.')}
          </li>

          <li>
            {t('Moving forward, your goal is to get the highest percent change possible in the coin you have chosen - to see the price rise as high as possible before “locking” your pick and holding on to the percent change you have at that moment.')}
          </li>

          <li>
            {t('Once you’ve chosen your memecoin and entered a game, you have two options.')}
            {' '}
            <ul style={{
              marginLeft: '40px',
              color: 'white',
              listStyleType: 'square',
            }}
            >
              <li>
                {t('If your analysis leads you to conclude that the price of your selected coin will increase through the end of the game, you can choose to stay in the game until it ends. In that scenario, your score is determined based on the percentage change—whether positive or negative—in the coin’s price between your opening price and the coin’s price when the game ends.')}
              </li>
              <li>
                {t('Alternatively, if your analysis leads you to determine that the price of your memecoin is at or near its high for the day before the game’s end, you can exit the game whenever you determine the time is right to lock in your percentage change for the game. If you lock your % change during the game, we record the official price of your selected memecoin at the moment of your lock—this is the closing price used to calculate your final percentage change for the game. In that scenario, your score is determined based on the percentage change—again, whether positive or negative—in the coin’s price between your opening price and your closing price.')}
              </li>
            </ul>
          </li>

          <li>
            {t('The Winning Coin. At the end of the game, the memecoin with the larger percentage increase from its price at the start of the game is declared the game’s winning coin.')}
          </li>

          <li>
            {t('The Winning Gamers. To be declared a winner, a gamer must (i) select the winning memecoin, and (ii) earn the highest % change—to finish among the top 10% highest % change among all winning participants in the game.')}
          </li>
        </ul>
      </Grid>
      <Grid sx={{ my: 3 }}>
        <b>{t('8. MG Tokens.')}</b>

        <ul
          style={{
            marginLeft: '40px',
            color: 'white',
            listStyleType: 'circle',
          }}
        >
          <li>
            {t('After every game, each winning gamer will be awarded a number of MG Tokens based on how highly the gamer’s % change ranked within the top 10% of all winning participants in the game. If a gamer accumulates a sufficient number of MG Tokens, the gamer may redeem those tokens for real publicly traded memecoins (more on this in the Redemption section below).')}
          </li>

          <li>
            {t('MG Tokens are not publicly traded or connected to a decentralized ledger. Although winning gamers may be able to redeem MG Tokens for publicly traded memecoins, the tokens have no actual economic value.')}
          </li>

          <li>
            {t('Awards for Winning Gamers. MG Tokens will be awarded to winning gamers as follows using the 100 MG coin game as an example:')}

            <ul style={{
              marginLeft: '40px',
              color: 'white',
              listStyleType: 'square',
            }}
            >
              <li>
                <b>{t('Top 1%: 10x MG Tokens. ')}</b>
                {t('Gamers whose % change rank within the top 1% will receive an award of tokens equal to ten times the game’s entry fee. Example: In the 100 coin game, finishers receive 1,000 MG tokens.')}
              </li>

              <li>
                <b>{t('Top 2%: 9x MG Tokens. ')}</b>
                {t('Gamers whose % change rank within the top 2% will receive an award of tokens equal to nine times the game’s entry fee. Example: In the 100 coin game, finishers receive 900 MG tokens.')}
              </li>

              <li>
                <b>{t('Top 3%: 8x MG Tokens. ')}</b>
                {t('Gamers whose % change rank within the top 3% will receive an award of tokens equal to eight times the game’s entry fee. Example: In the 100 coin game, finishers receive 800 MG tokens.')}
              </li>

              <li>
                <b>{t('Top 4%: 7x MG Tokens. ')}</b>
                {t('Gamers whose % change rank within the top 4% will receive an award of tokens equal to seven times the game’s entry fee. Example: In the 100 coin game, finishers receive 700 MG tokens.')}
              </li>

              <li>
                <b>{t('Top 5%: 6x MG Tokens. ')}</b>
                {t('Gamers whose % change rank within the top 5% will receive an award of tokens equal to six times the game’s entry fee. Example: In the 100 coin game, finishers receive 600 MG tokens.')}
              </li>

              <li>
                <b>{t('Top 6%: 5x MG Tokens. ')}</b>
                {t('Gamers whose % change rank within the top 6% will receive an award of tokens equal to five times the game’s entry fee. Example: In the 100 coin game, finishers receive 500 MG tokens.')}
              </li>

              <li>
                <b>{t('Top 7%: 4x MG Tokens. ')}</b>
                {t('Gamers whose % change rank within the top 7% will receive an award of tokens equal to four times the game’s entry fee. Example: In the 100 coin game, finishers receive 400 MG tokens.')}
              </li>

              <li>
                <b>{t('Top 8%: 3x MG Tokens. ')}</b>
                {t('Gamers whose % change rank within the top 8% will receive an award of tokens equal to three times the game’s entry fee. Example: In the 100 coin game, finishers receive 300 MG tokens.')}
              </li>

              <li>
                <b>{t('Top 9%: 2x MG Tokens. ')}</b>
                {t('Gamers whose % change rank within the top 9% will receive an award of tokens equal to two times the game’s entry fee. Example: In the 100 coin game, finishers receive 200 MG tokens.')}
              </li>

              <li>
                <b>{t('Top 10%: MG Tokens. ')}</b>
                {t('Gamers whose % change rank within the top 10% will receive an award of tokens equal to one times the game’s entry fee. Example: In the 100 coin game, finishers receive 100 MG tokens.')}
              </li>
            </ul>
          </li>

          <li>
            {t('Gamers that choose the winning memecoin but do not score in the top 10% will be refunded their MG Coin-entry fee back to their Gamer Wallets with an additional 10 MG coin bonus.')}
          </li>

          <li>
            {t('Gamers that choose the losing coin are refunded their MG Coins.')}
          </li>

          <li>
            {t('When MG Coins are refunded they retain all of their initial attributes, including their original expiration dates.')}
          </li>

          <li>
            {t('There is currently no expiration on MG Tokens.')}
          </li>
        </ul>
      </Grid>

      <Grid sx={{ my: 3 }}>
        <b>
          {t('9. Redemption')}
        </b>
        <br />
        {t('The most skilled gamers who play our games may become eligible to redeem their MG Tokens for real publicly traded memecoins. Although those may be connected to a decentralized ledger and publicly traded, they have no actual economic value and are intended for entertainment purposes only. Gamers who redeem MG Tokens for an award of memecoins should have no expectation that the price of those coins will increase, maintain their present value, or even remain publicly traded.')}

        <ul
          style={{
            marginLeft: '40px',
            color: 'white',
            listStyleType: 'circle',
          }}
        >
          <li>
            {t('Redeem for Packs. Starting at 1,000 MG Tokens, you become available to purchase a MG Starter Pack, allowing you to continue your journey another 30 days without paying for MG Coins.')}
          </li>

          <li>
            {t('Eligibility to Redeem. When you accumulate a minimum of 2,000 MG Tokens, you become eligible to exchange those tokens for any one of the publicly traded memecoins that you selected in any game in which you were declared a winner (you picked the winning coin).')}
          </li>

          <li>
            {t('Redemption Limits. You only may redeem MG Tokens in increments of 2,000. However, there are no limits on how often gamers may redeem. Thus, if you had accumulated 10,000 tokens, you may redeem all 10,000 tokens by submitting 5 redemption requests.')}
          </li>

          <li>
            {t('Calculating Your Memecoin Award. When you redeem 2,000 MG Tokens, you will receive $200 worth of your chosen memecoin at the current price of that coin at the time we process all redemption requests submitted by gamers. We reserve the right to process redemption requests at different times on different day, and MemeGames will determine such timing each day in its sole and absolute discretion.')}
          </li>

          <li>
            {t('Delivery of Your Memecoins. When you redeem MG Tokens for memecoins, your memecoins will be delivered to your designated Solana wallet by no later than the Friday of the week after you submitted your redemption before 12am GMT. At the time of fulfillment, if the coin is not available, $200 USD in Solana at the time of the redemption is processed will be sent instead.')}
            <ul style={{
              marginLeft: '40px',
              color: 'white',
              listStyleType: 'square',
            }}
            >
              <li>
                {t('By way of example, if a gamer submits a redemption request on a Monday, the redeeming gamer\'s memecoin award will be delivered by no later than 12 a.m. GMT on Friday of the following week.')}
              </li>
            </ul>
          </li>

          <li>
            {t('You must be an active gamer to redeem your tokens. An active gamer is one that has activated a game pack and played a memegame within the last 30 days.')}
          </li>
        </ul>
      </Grid>

    </Container>
  );
}

import { useContext, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import useApi from '../../../utils/api';
import { defaultFormValues, formValidationConfig, getFormConfig } from './config';
import { validateAll } from '../../../components/Form/config';
// import useAppStore from '../../../utils/store/app/hook';
import { AppContext } from '../../../utils/store/app';

export default function useRanks() {
  const apiBaseUri = '/ranks';
  const { authPut, authPost } = useApi();
  const {
    ranks, loadingRanks, getRanks,
  } = useContext(AppContext);
  const [errors, setErrors] = useState(null);
  const [selectedRanks, setSelectedRanks] = useState();
  const [message, setMessage] = useState();
  const [formLoading, setFormLoading] = useState(loadingRanks);
  const { t } = useTranslation();

  const onRanksClick = (_data) => {
    setMessage();
    setErrors();
    if (_data) {
      setSelectedRanks(_data);
    } else {
      setSelectedRanks(defaultFormValues);
    }
  };

  const formConfig = useMemo(() => getFormConfig(t), [t]);

  const handleModalClose = () => {
    setSelectedRanks();
    setErrors(null);
    setMessage();
    setFormLoading(false);
  };

  const submit = async (data) => {
    setFormLoading(true);
    try {
      const checkform = validateAll(data, formValidationConfig);
      if (checkform) {
        setErrors(checkform);
        setFormLoading(false);
      } else if (data.id) {
        const _data = { ...data };
        _data.rank = Number(data.rank || 0);
        _data.minTv = Number(data.minTv || 0);
        _data.minPv = Number(data.minPv || 0);
        _data.match = Number(data.match || 0);
        _data.commission = Number(data.commission || 0);
        const res = await authPut(`${apiBaseUri}/${data.id}`, { data: _data });
        if (res?.id) {
          handleModalClose();
          // createRanks(res);
          setFormLoading(false);
          getRanks();
          toast.success(t('Rank Saved Successfully!'));
        }
      } else {
        const res = await authPost(apiBaseUri, { data });
        if (res?.id) {
          toast.success(t('Rank Saved Successfully!'));
          handleModalClose();
          // updateRanks(res);
          setFormLoading(false);
          getRanks();
        }
      }
    } catch (err) {
      console.log('-----passTypes---', err);
      setErrors(err);
      setFormLoading(false);
      toast.error(t('Error! Something went wrong!'));
    }
  };

  return {
    submit,
    errors,
    setErrors,
    onRanksClick,
    selectedRanks,
    handleModalClose,
    message,
    formLoading,
    ranks,
    formConfig,
  };
}

const config = {
  viewBox: '0 0 17 17',
  children: (
    <>
      <circle
        cx="8.70312"
        cy="4.86719"
        r="4"
        stroke="url(#paint0_linear_4782_1001)"
      />
      <path
        d="M16.2031 16.3672V16.3672C16.2031 13.6058 13.9645 11.3672 11.2031 11.3672H6.20312C3.4417 11.3672 1.20312 13.6058 1.20312 16.3672V16.3672"
        stroke="url(#paint1_linear_4782_1001)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_4782_1001"
          x1="4.20312"
          y1="4.86719"
          x2="13.2031"
          y2="4.86719"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#78E4FF" />
          <stop offset="1" stopColor="#DE85FF" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_4782_1001"
          x1="1.20312"
          y1="13.8672"
          x2="16.2031"
          y2="13.8672"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#78E4FF" />
          <stop offset="1" stopColor="#DE85FF" />
        </linearGradient>
      </defs>
    </>
  ),
};

export default config;

import {
  useCallback, useContext, useEffect, useMemo, useState, 
} from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { AuthenticationContext } from '../../../../utils/store/authentication';
import useApi from '../../../../utils/api';
import { defaultWithdrawColumnsConfig } from '../config';

const usePendingWithdrawApi = () => {
  const { authGet, authPut } = useApi();

  const { t } = useTranslation();
  const { user } = useContext(AuthenticationContext);
  const [withdrawData, setWithdrawData] = useState([]);
  const [withdrawAmountTotal, setWithdrawAmountTotal] = useState(0);
  const [loadedWithdrawData, setLoadedWithdrawData] = useState(false);
  const [withdrawDestinationDataModal, setWithdrawDestinationDataModal] = useState(null);
  const [submittingPayWithdrawModal, setSubmittingPayWithdrawModal] = useState(false);

  const fetchWithdrawData = useCallback(
    async () => {
      setLoadedWithdrawData(false);
      const response = await authGet('/withdraw/pending');
      
      setWithdrawData(response);
      
      setWithdrawAmountTotal(response?.reduce((acc, withdraw) => acc + withdraw.amount, 0));

      setLoadedWithdrawData(true);
    },
    [authGet],
  );

  const handlePayWithdrawSubmitModal = useCallback(
    async (transactionId) => {
      if (!transactionId) {
        toast.error('Missing transaction ID.');
        return;
      }

      setSubmittingPayWithdrawModal(true);

      if (withdrawDestinationDataModal) {
        try {
          await authPut(`/withdraw/${withdrawDestinationDataModal.withdrawId}`, { data: { transactionId } });

          setWithdrawDestinationDataModal(null);

          fetchWithdrawData();

          toast.success(`Marked as completed with transaction ID: ${transactionId}`);
        } catch (err) {
          toast.error('Error trying to mark the withdraw as Paid.');
        }
      }

      setSubmittingPayWithdrawModal(false);
    },
    [authPut, withdrawDestinationDataModal],
  );

  const handlePayWithdrawCloseModal = useCallback(() => {
    setWithdrawDestinationDataModal(null);
  }, [setWithdrawDestinationDataModal]);

  useEffect(() => {
    fetchWithdrawData();
  }, []);

  // Columns Configuration ===================================
  const withdrawDataColumnsConfiguration = useMemo(
    () => defaultWithdrawColumnsConfig(t, setWithdrawDestinationDataModal, withdrawAmountTotal),
    [t, setWithdrawDestinationDataModal, withdrawAmountTotal],
  );
  // Columns Configuration ===================================

  return {
    t,
    user,

    fetchWithdrawData,
    withdrawDataColumnsConfiguration,
    withdrawData,
    loadedWithdrawData,
    
    withdrawDestinationDataModal,
    submittingPayWithdrawModal,
    setWithdrawDestinationDataModal,
    handlePayWithdrawSubmitModal,
    handlePayWithdrawCloseModal,
  };
};

export default usePendingWithdrawApi;

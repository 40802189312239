const config = {
  viewBox: '0 0 18 16',
  children: (
    <>
      <path
        d="M3.86719 10.382L8.26614 5.47744L12.4364 9.89086L19.4687 2.49416"
        stroke="url(#paint0_linear_4900_972)"
        strokeWidth="1.17924"
        strokeLinecap="round"
      />
      <path
        d="M1 1.25V15.5093"
        stroke="url(#paint1_linear_4900_972)"
        strokeWidth="1.17924"
        strokeLinecap="round"
      />
      <path
        d="M14.7188 15.5088L1.00012 15.5088"
        stroke="url(#paint2_linear_4900_972)"
        strokeWidth="1.17924"
        strokeLinecap="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_4900_972"
          x1="3.86719"
          y1="6.43808"
          x2="19.4687"
          y2="6.43808"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#78E4FF" />
          <stop offset="1" stopColor="#DE85FF" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_4900_972"
          x1="1"
          y1="8.37963"
          x2="2"
          y2="8.37963"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#78E4FF" />
          <stop offset="1" stopColor="#DE85FF" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_4900_972"
          x1="7.85944"
          y1="15.5088"
          x2="7.85944"
          y2="16.5088"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#78E4FF" />
          <stop offset="1" stopColor="#DE85FF" />
        </linearGradient>
      </defs>
    </>
  ),
};

export default config;

/* eslint-disable max-len */
import { useContext } from 'react';
import Modal from '../../../../components/Modal';
import { Context } from '../../context';
import { transferCoinsFormConfig } from './formConfig';
import Form from '../../../../components/Form';
import ConfirmationModal from './confirmationModal';

export default function TransferCoinsModal({ open, setOpenCoinTransferModal }) {
  const {
    transferCoinsLoading,
    onTransferCoinsSubmit,
    coinTrasferErrors,
    setCoinTrasferErrors,
    transferCoinsData,
  } = useContext(Context);

  return (
    <Modal
      width="550px"
      title="Transfer Coins"
      open={open}
      handleClose={() => {
        setCoinTrasferErrors(null);
        setOpenCoinTransferModal(false);
      }}
    >
      <Form
        config={transferCoinsFormConfig}
        size="sm"
        defaultFormValue={transferCoinsData}
        onSubmit={onTransferCoinsSubmit}
        formLoading={transferCoinsLoading}
        submitLabel="Transfer"
        errors={coinTrasferErrors}
        setErrors={setCoinTrasferErrors}
      />
      <ConfirmationModal setOpenCoinTransferModal={setOpenCoinTransferModal} />
    </Modal>
  );
}

import { Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import Layout from '../../../../components/CardLayout';

export default function PassTypeCard({ data, onRanksClick = () => { } }) {
  return (
    <Layout>
      <Typography
        sx={{
          fontWeight: 600,
          color: 'var(--primary-color)',
          fontSize: '16px',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        {data.name}
        <EditIcon
          sx={{ cursor: 'pointer' }}
          color="primary"
          onClick={() => {
            onRanksClick(data);
          }}
        />
      </Typography>
      <div className="divider" />
      <Typography sx={{ opacity: 0.5 }}>{data.description}</Typography>
      <Typography>
        Rank :
        <span className="pl-2">
          {data?.rank || 0}
        </span>
      </Typography>
      <Typography>
        Daily Pay :
        <span className="pl-2">
          $
          {data?.dailyPay?.toLocaleString() || 0}
        </span>
      </Typography>
      <Typography>
        Yearly Earning :
        <span className="pl-2">
          $
          {data?.yearlyEarning?.toLocaleString() || 0}
        </span>
      </Typography>
      <Typography>
        Team Volume :
        <span className="pl-2">
          $
          {data?.minTv?.toLocaleString() || 0}
        </span>
      </Typography>
    </Layout>
  );
}

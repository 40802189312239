/* eslint-disable max-len */
import { useContext } from 'react';
import { Container, Grid, Typography } from '@mui/material';
import Modal from '../../../../components/Modal';
import { Context } from '../../context';
import Button from '../../../../components/Button';

export default function ConfirmationModal({ setOpenCoinTransferModal }) {
  const {
    t,
    onCloseTransferCoinsModal,
    transferCoinsData,
    openConfirmModal,
    onConfirmTransferCoins,
    transferCoinsLoading,
  } = useContext(Context);

  return (
    <Modal width="550px" title={t('Transfer Confirmation')} open={openConfirmModal} handleClose={onCloseTransferCoinsModal}>
      <Container>
        <Grid
          container
          columnSpacing={2}
          justifyContent="space-between"
          alignContent="center"
          alignItems="center"
        >
          <Grid item xl={12} lg={12} md={12}>
            <Typography sx={{
              display: 'flex', justifyContent: 'center',
            }}
            >
              {t('Are you sure you want to transfer the following amount of coins?')}
            </Typography>

          </Grid>

          <Grid item xl={6} lg={6} md={6}>
            <Button
              type="button"
              disabled={transferCoinsLoading}
              className="flex-1 mt-3"
              bordered
              style={{ width: '100%' }}
              onClick={onCloseTransferCoinsModal}
            >
              <span>{t('Cancel')}</span>
            </Button>
          </Grid>
          <Grid item xl={6} lg={6} md={6}>
            <Button
              type="button"
              disabled={transferCoinsLoading}
              className="flex-1 mt-3"
              style={{ width: '100%' }}
              onClick={() => {
                onConfirmTransferCoins(transferCoinsData);
                setOpenCoinTransferModal(false);
              }}
            >
              <span>{t('Continue')}</span>
            </Button>
          </Grid>

        </Grid>

      </Container>
    </Modal>
  );
}

import {
  useCallback, useContext, useEffect, useMemo, useState, 
} from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { AuthenticationContext } from '../../../../utils/store/authentication';
import useApi from '../../../../utils/api';
import { defaultRedeemColumnsConfig } from '../config';

const usePendingRedeemApi = () => {
  const { authGet, authPut } = useApi();

  const { t } = useTranslation();
  const { user } = useContext(AuthenticationContext);
  const [redemptionsData, setRedemptionsData] = useState([]);
  const [loadedRedemptionsData, setLoadedRedemptionsData] = useState(false);
  const [redeemDestinationData, setRedeemDestinationData] = useState(null);
  const [submittingRedeemModal, setSubmittingRedeemModal] = useState(false);

  const fetchRedeemData = useCallback(
    async () => {
      setLoadedRedemptionsData(false);
      const response = await authGet('/redemptions/pending');
      
      setRedemptionsData(response);

      setLoadedRedemptionsData(true);
    },
    [authGet],
  );

  const handlePayRedeemSubmitModal = useCallback(
    async (memeCoinsAmount) => {
      if (!memeCoinsAmount) {
        toast.error('Missing Meme Coins amount.');
        return;
      }
      setSubmittingRedeemModal(true);
      if (redeemDestinationData) {
        try {
          await authPut(`/redemptions/${redeemDestinationData.redeemId}/completed`, { data: { memeCoinsAmount } });

          setRedeemDestinationData(null);

          fetchRedeemData();

          toast.success(`Marked as completed with Meme Coins amount: ${memeCoinsAmount}`);
        } catch (err) {
          toast.error('Error trying to mark the redeem as Paid.');
        }
      }
      setSubmittingRedeemModal(false);
    },
    [authPut, redeemDestinationData],
  );

  const handlePayRedeemCloseModal = useCallback(() => {
    setRedeemDestinationData(null);
  }, [setRedeemDestinationData]);

  useEffect(() => {
    fetchRedeemData();
  }, []);

  // Columns Configuration ===================================
  const redemptionsDataColumnsConfiguration = useMemo(
    () => defaultRedeemColumnsConfig(t, setRedeemDestinationData),
    [t, setRedeemDestinationData],
  );
  // Columns Configuration ===================================

  return {
    t,
    user,

    fetchRedeemData,
    redemptionsDataColumnsConfiguration,
    redemptionsData,
    loadedRedemptionsData,
    
    redeemDestinationData,
    submittingRedeemModal,
    setRedeemDestinationData,
    handlePayRedeemSubmitModal,
    handlePayRedeemCloseModal,
  };
};

export default usePendingRedeemApi;

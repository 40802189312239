import { useMemo, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { CircularProgress, Alert } from '@mui/material';
import { getFormConfig } from './config';
import useAuthenticationApi from '../api';
import Form from '../../../components/Form';
import Layout from '../../../components/AuthLayout';
import { useAuthentication } from '../../../utils/store';

export default function LoginAdmin() {
  const { t } = useTranslation();
  const formConfig = useMemo(() => getFormConfig(t), [t]);
  const { authenticateUser } = useAuthentication();
  const { logInAdmin } = useAuthenticationApi();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(null);
  const navigate = useNavigate();

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      const res = await logInAdmin(data);
      authenticateUser(res?.user, res?.token);
      if (res?.user?.SID && res.token) {
        // window.location.href = '/';
        navigate('/');
      }
    } catch (err) {
      let msg = err;
      if (err === 'Unauthorized') {
        msg = 'Invalid credentials';
      }
      setError(msg);
    }
    setLoading(false);
  };

  return (
    <Layout>
      <div className="admin-login" style={{ position: 'relative' }}>
        {loading && (
          <div
            className="flex justify-center"
            style={{
              position: 'absolute',
              zIndex: '2',
              top: '50%',
              left: '45%',
            }}
          >
            <CircularProgress />
          </div>
        )}
        {error && (
          <Alert className="error" severity="error">
            {error?.status === 500 ? 'An Error occurred. Please try again later!' : error}
          </Alert>
        )}
        <div className="flex-row justify-center mb-12">
          <img alt="logo-black" src="/assets/MemeGames-logo-sol.png" />
        </div>

        <Form
          config={formConfig}
          onSubmit={onSubmit}
          formLoading={loading}
          submitLabel="Login"
          buttonType="button"
          submitClassName="full-width"
        />

        <p className="mb-12 mt-4 forgot-pass">
          {t("Don't have an account yet?")}
          {' '}
          <Link to="/">{t('Sign Up.')}</Link>
        </p>

        <p>
          {t(
            'By Loging in you agree to be bound by our Privacy Policy, Terms of Use, Policies and Procedures',
          )}
        </p>
      </div>
    </Layout>
  );
}

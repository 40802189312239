import { useContext } from 'react';
import Modal from '../../../../components/Modal';
import { AdminFAQContext } from '../context';
import Form from '../../../../components/Form';
import { faqFormConfig } from '../config';

const AddEditFAQModal = () => {
  const {
    t,
    faq,
    handleCloseModal,
    handleSubmitFormModal,
    faqFormLoading,

    faqFormErrors,
    setFAQFormErrors,
  } = useContext(AdminFAQContext);

  const isEdit = faq?.id?.length > 0;

  return (
    <Modal
      title={!isEdit ? t('Add New FAQ') : t('Edit FAQ')}
      open={!!faq}
      handleClose={handleCloseModal}
      width="850px"
    >
      <Form
        config={faqFormConfig}
        errors={faqFormErrors}
        setErrors={setFAQFormErrors}
        onSubmit={handleSubmitFormModal}
        defaultFormValue={faq}
        formLoading={faqFormLoading}
        buttonType="button"
        buttonStyle={{ width: '100%' }}
      />
    </Modal>
  );
};

export default AddEditFAQModal;

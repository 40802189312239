import React, { useState, useContext } from 'react';
import {
  Container, FormControlLabel, Grid, Switch, Typography, 
} from '@mui/material';
import { Provider, Context } from './context';
// import Banner from '../../../components/Banner';
import Teams from './components/Teams';
import UsersTree from './components/Tree';
import UserModal from './components/UserModal';
import ActiveTeamModal from './components/ChangeTeamModal';
// import { PROMO_MESSAGES } from '../../../constants';
import useScreenSize from '../../../utils/hooks/useScreenSize';
import CustomAccordion from '../../../components/Accordion';
import TreeColorsKey from './components/TreeColorsKey';
import Layout from '../../../components/CardLayout';
import AffiliateLink from '../../../components/AffiliateLink';

export function GenealogyTree() {
  const [isOpen, setIsOpen] = useState(false);
  const { isMobile } = useScreenSize();
  const {
    stats,
    loadingUsers,
    loadingUsersSponsored,
    onlySponsorsToggle,
    setOnlySponsorsToggle,
  } = useContext(Context);

  return (
    <div>
      <Layout maxWidth="full" className="mb-10">
        <Grid container justifyContent="flex-end">
          <Grid item xs={12} lg={7} xl={6}><AffiliateLink /></Grid>
        </Grid>
      </Layout>
      {isMobile ? (
        <div className="teams-accordion-container">
          <CustomAccordion
            expanded={isOpen}
            setExpanded={() => setIsOpen((current) => !current)}
            summary={<p>My Team</p>}
            details={<Teams />}
          />
        </div>
      ) : (
        <Teams />
      )}
      <Container>
        <div className="flex row-items">
          <Typography variant="span">
            {`Ambassadors: ${stats?.totalAmbassadors || 0}`}
          </Typography>
          <Typography variant="span" sx={{ marginLeft: '20px' }}>
            {`Active Ambassadors: ${stats?.activeAmbassadors || 0}`}
          </Typography>
        </div>
        <div className="pt-4">
          <FormControlLabel
            control={(
              <Switch
                name="only.sponsors.toggle"
                disabled={loadingUsersSponsored || loadingUsers}
                checked={onlySponsorsToggle}
                color="primary"
                onChange={() => { setOnlySponsorsToggle((current) => !current); }}
              />
            )}
            label={onlySponsorsToggle ? 'Showing only direct Sponsored' : 'Showing Complete Tree'}
          />
        </div>
      </Container>

      <div className="flex justify-center" style={{ position: 'relative', width: '100%' }}>
        <TreeColorsKey />
        <UsersTree />
      </div>
      <UserModal />
      <ActiveTeamModal />
    </div>
  );
}

export default () => (
  <Provider>
    <GenealogyTree />
  </Provider>
);

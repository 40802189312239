// Default color theme
export const defaultTheme = {
  PRIMARY_COLOR: '#78E4FF',
  SECONDARY_COLOR: '#DE85FF',
  // PRIMARY_COLOR: '#D8A878',
  // SECONDARY_COLOR: '#C17B47',
  BACKGROUND_GRADIENT: 'linear-gradient(to right, #78E4FF, #DE85FF)',
  BLACK: 'black',
  TEXT_COLOR: 'white',
  BACKGROUND_COLOR: '#0E0F12',
  WHITE: '#FFFFFF',
  FONT_FAMILY: 'Montserrat',
  WARNING: '#CE965F',
  MODE: 'dark',
  COLLAPSE_BUTTON: '#111215',
  TABLE_ROW_BORDER: 'rgba(255, 255, 255, 0.1)',
  PAPER: '#141518',
  DANGER: '#4D2626',
  SUCCESS: '#80DDBB',
  BORDER_COLOR: '#FFFFFF',
  DISABLED_BACKGROUND_COLOR: '#D8D8D8',
  TABLE_COLOR:
    'linear-gradient(45deg, rgba(0, 0, 0, 0.04) 0%, rgba(255, 255, 255, 0.04) 100%), #141518',
  BROWN_BUTTON:
    'linear-gradient(266deg, rgba(255, 255, 255, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), #453628',
  DARK_RED_BUTTON:
    'linear-gradient(266deg, rgba(255, 255, 255, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), #4D2626',
  PURPLE_BUTTON:
    'linear-gradient(266deg, rgba(255, 255, 255, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), #3B264D',
};

export default defaultTheme;

import { useContext, useMemo } from 'react';
import Twitter from '@mui/icons-material/Twitter';
import Facebook from '@mui/icons-material/Facebook';
import copy from 'copy-to-clipboard';
import { toast } from 'react-toastify';
import TextInput from '../Inputs/TextInput';
import { AuthenticationContext } from '../../utils/store/authentication';
import Button from '../Button';

const AffiliateLink = () => {
  const { user } = useContext(AuthenticationContext);
  const referralLink = useMemo(() => `https://${user.Username}.${process.env.REACT_APP_BASE_DOMAIN}`, [user]);
  const shareTwitterLink = useMemo(() => `https://x.com/intent/tweet?url=https://${referralLink}&text=${'Join me on MemeGames.ai, the ultimate Memecoin competition!'}`, [referralLink]);
  const shareFacebookLink = useMemo(() => `https://www.facebook.com/share_channel/?link=https://${referralLink}&source_surface=external_reshare&display=popup&hashtag`, [referralLink]);

  return (
    <TextInput
      readOnly
      size="sm"
      value={referralLink}
      endAdornment={(
        <div className="p-1 flex">
          <Button
            onClick={() => {
              toast.success('Link Copied!');
              copy(referralLink);
            }}
            className="py-auto my-auto mr-1"
            bordered
            size="sm"
          >
            <span className="no-wrap">COPY LINK</span>
          </Button>
          <div className="flex flex-column justify-center">
            <div className="flex ml-1 mt-1">
              <a
                className="mr-1 cursor-pointer"
                href={shareTwitterLink}
                target="_blank"
                rel="noreferrer"
                aria-label="twitter-link"
              >
                <Twitter sx={{ fontSize: 20 }} color="primary" />
              </a>
              <a
                className="mr-1 cursor-pointer"
                href={shareFacebookLink}
                target="_blank"
                rel="noreferrer"
                aria-label="facebook-link"
              >
                <Facebook sx={{ fontSize: 20 }} color="primary" />
              </a>
            </div>
          </div>
        </div>
      )}
    />
  );
};

export default AffiliateLink;

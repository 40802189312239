import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useContext, useState } from 'react';
import { toast } from 'react-toastify';
import Button from '../../../components/Button';
import Modal from '../../../components/Modal';
import ComponentLoader from '../../../components/ComponentLoader';
import TextInput from '../../../components/Inputs/TextInput';
import { Context } from '../context';
import SolanaTokenRow from './solanaTokenRow';

export default function SolanaWallet({ showMessage = true }) {
  const {
    onSaveSolanaWallet,
    solanaWallet,
    defaultWalletLoaded,
    onDeleteSolanaWallet,
    setSolanaWallet,
    solanaBalance,
    solanaTokensData,
    solanaTokensDataLoaded,
  } = useContext(Context);
  const { t } = useTranslation();
  const [solanaKey, setSolanaKey] = useState('');
  const [openSolanaModal, setOpenSolanaModal] = useState(false);
  const [openSolanaDeleteModal, setOpenSolanaDeleteModal] = useState(false);

  const handleOnDeleteWallet = async (walletId) => {
    try {
      const deleted = await onDeleteSolanaWallet(walletId);
      if (deleted?.deletedCount > 0) {
        toast.success('Solana Wallet deleted.');
        setSolanaWallet(null);
      } else {
        toast.error('Can\'t delete your wallet at this moment. Please, try again ma');
      }
    } catch (err) {
      console.log(err);
      toast.error('Can\'t delete your wallet at this moment. Please, try again ma');
    }

    setOpenSolanaDeleteModal(false);
  };

  return (
    <>
      <Grid container sx={{ pt: 3 }} className="mt-6">
        <Grid
          item
          xs={12}
        >
          <Typography sx={{
            fontWeight: 600, textAlign: 'center', fontSize: '16px',
          }}
          >
            <span className="primary-text-gradient">{t('Solana Wallet')}</span>
          </Typography>
        </Grid>

        <Grid
          item
          xs={12}
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            paddingY: solanaWallet ? 1 : 3,
          }}
        >
          {(!solanaWallet && !defaultWalletLoaded) && <ComponentLoader />}
          {(!solanaWallet && defaultWalletLoaded) && (
            <Button
              onClick={() => {
                setOpenSolanaModal(true);
              }}
              className="my-auto"
            >
              <span className="no-wrap" style={{ padding: '0 32px' }}>{t('Connect')}</span>
            </Button>
          )}
        </Grid>

        {!!solanaWallet && (
          <>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12} sx={{ marginBottom: 1 }}>
              <TextInput
                className="wallet-input"
                value={solanaBalance.toString()}
                readOnly
                startAdornment={(
                  <span className="flex items-center">
                    <Typography sx={{
                      pl: 2, opacity: 0.5, fontWeight: 500, display: 'flex', justifyContent: 'flex-end', alignItems: 'center',
                    }}
                    >
                      {solanaWallet.currency}
                    </Typography>
                  </span>
                )}
                endAdornment={(
                  <div className="flex" style={{ alignItems: 'center', paddingRight: '16px' }}>
                    <Button
                      onClick={() => {
                        setOpenSolanaDeleteModal(true);
                      }}
                      bordered
                      size="sm"
                    >
                      <span>Delete</span>
                    </Button>
                  </div>
                )}
              />
            </Grid>
            {showMessage && (
              <Typography sx={{
                padding: 1, fontSize: '14px', opacity: 0.5, textAlign: { xs: 'center' },
              }}
              >
                {t('Redemptions for Meme Coins are in 2,000 token increments. You may chose to redeem in coin packs, which are available immediately. You may redeem for any Meme Coin that you’ve won with, which will be sent to your connected Solana wallet the Friday of the week following your redemption.')}
              </Typography>
            )}
            {solanaTokensDataLoaded && (
              solanaTokensData?.length > 0 ? (
                solanaTokensData.filter((token) => Number(token?.balance || 0) > 0 && !!token?.name).map((token, idx) => (
                  <Grid key={idx} container>
                    {idx === 0 && (
                      <Grid container paddingY="12px" borderBottom="1px solid rgba(255,255,255, 0.1)">
                        <Grid item xs={12}>
                          <Typography
                            fontSize="16px"
                            fontWeight="600"
                            marginBottom="12px"
                            sx={{ fontSize: { md: '18px' } }}
                            paddingTop="24px"
                          >
                            Meme Coins
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography
                            fontSize="8px"
                            fontWeight="600"
                            color="rgba(255, 255, 255, 0.7)"
                            sx={{ fontSize: { md: '18px' } }}
                          >
                            Token
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography
                            fontSize="8px"
                            fontWeight="600"
                            color="rgba(255, 255, 255, 0.7)"
                            sx={{ fontSize: { md: '18px' } }}
                          >
                            Balance
                          </Typography>
                        </Grid>
                      </Grid>
                    )}
                    <SolanaTokenRow token={token} />
                  </Grid>
                ))
              ) : null
            )}
          </>
        )}
      </Grid>
      <Modal width="" open={!!openSolanaDeleteModal} handleClose={() => { setOpenSolanaDeleteModal(false); }} title="Delete Wallet">
        <Grid container columnSpacing={1}>
          <Grid item xl={12} lg={12} sm={12} md={12} position="relative" display="flex" flexDirection="column">
            <Typography style={{ textAlign: 'center' }}>
              {' '}
              Are you sure you want to delete
              <span style={{
                color: 'var(--primary-color)', textTransform: 'uppercase', marginLeft: '3px', marginRight: '3px',
              }}
              >
                your Solana Wallet
              </span>
              ?
            </Typography>
            <Typography style={{
              textAlign: 'center',
            }}
            >
              Wallet Address:
              {' '}
              <span style={{
                color: 'var(--primary-color)', marginLeft: '3px', marginRight: '3px', wordBreak: 'break-all',
              }}
              >
                {solanaWallet?.publicKey}
              </span>
            </Typography>
          </Grid>

          <Grid item xl={6} lg={6} sm={12} xs={12} sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
            <Button onClick={() => handleOnDeleteWallet(solanaWallet.id)} style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>Delete</Button>
          </Grid>

          <Grid item xl={6} lg={6} sm={12} xs={12} sx={{ display: 'flex', justifyContent: 'center', mt: { xs: 2, lg: 3 } }}>
            <Button style={{ display: 'flex', width: '100%', justifyContent: 'center' }} bordered onClick={() => { setOpenSolanaDeleteModal(false); }}>
              Cancel
            </Button>
          </Grid>
        </Grid>
      </Modal>
      <Modal
        open={openSolanaModal}
        width="700px"
        title="Add Solana Wallet"
        handleClose={() => {
          setOpenSolanaModal(false);
        }}
      >
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12} sx={{ marginBottom: 2 }}>
          <TextInput
            value={solanaKey}
            onChange={(e) => {
              setSolanaKey(e);
            }}
            placeholder="Your Solana Wallet public Key"
            className="mb-5"
          />
          <Button
            onClick={async () => {
              if (solanaKey?.length > 1) {
                onSaveSolanaWallet(solanaKey);
                setOpenSolanaModal(false);
              }
            }}
            disabled={false}
          >
            <span className="no-wrap">{t('Connect Wallet')}</span>
          </Button>
        </Grid>
      </Modal>
    </>
  );
}

import { useContext } from 'react';
import { Grid, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { AdminFAQContext } from '../context';
import Modal from '../../../../components/Modal';
import Button from '../../../../components/Button';
import TextInput from '../../../../components/Inputs/TextInput';
import ComponentLoader from '../../../../components/ComponentLoader';

const FAQCategoriesModal = () => {
  const {
    t,
    faqCategoryData,
    faqCategoryDataLoaded,
    openFAQCategoryModal,
    setOpenFAQCategoryModal,
    faqCategorySingle,
    setFAQCategorySingle,
    handleAddCategorySubmit,
    handleOpenFAQCategoryDeleteModal,
  } = useContext(AdminFAQContext);

  return (
    <Modal
      title="FAQ Categories"
      open={openFAQCategoryModal}
      handleClose={() => setOpenFAQCategoryModal(false)}
      width="600px"
    >
      <TextInput
        className="mb-4"
        value={faqCategorySingle.title}
        onChange={(e) => {
          setFAQCategorySingle({ id: faqCategorySingle.id, title: e });
          if (!(e.length > 0)) {
            setFAQCategorySingle({ id: '', title: '' });
          }
        }}
        endAdornment={(
          <div className="flex items-center">
            <Button
              onClick={() => {
                if (faqCategorySingle?.title.length > 0 && faqCategoryDataLoaded) {
                  handleAddCategorySubmit(faqCategorySingle);
                }
              }}
              className="py-auto my-auto mr-3"
              bordered={!(faqCategorySingle?.title.length > 0) || !faqCategoryDataLoaded}
              disabled={!(faqCategorySingle?.title.length > 0) || !faqCategoryDataLoaded}
              size="sm"
            >
              <span className="no-wrap pl-2 pr-2">{faqCategorySingle.id.length > 0 ? t('Edit') : t('Add')}</span>
            </Button>
          </div>
        )}
      />

      {faqCategoryDataLoaded ? (
        <Grid container className="faqs" paddingTop={4}>
          <Grid item xs={8} md={10}>
            <Typography fontSize="16px" fontWeight="600">
              Category
            </Typography>
          </Grid>
          <Grid item xs={4} md={2}>
            <Typography fontSize="16px" fontWeight="600">
              Actions
            </Typography>
          </Grid>
          {faqCategoryData.length > 0 && faqCategoryData.map((cat, idx) => (
            <Grid container key={idx}>
              <Grid item xs={12}>
                <div className="divider" />
              </Grid>
              <Grid item xs={8} md={10}>
                <Typography fontSize="14px" paddingLeft={1}>
                  {cat.title}
                </Typography>
              </Grid>
              <Grid item xs={4} md={2} display="flex" gap={2}>
                <EditIcon
                  sx={{ cursor: 'pointer', display: 'block' }}
                  color="primary"
                  onClick={() => setFAQCategorySingle({ id: cat.id, title: cat.title })}
                />
                <DeleteIcon
                  sx={{ cursor: 'pointer', display: 'block' }}
                  color="primary"
                  onClick={() => handleOpenFAQCategoryDeleteModal({ id: cat.id, title: cat.title })}
                />
              </Grid>
            </Grid>
          ))}
        </Grid>
      ) : (
        <ComponentLoader />
      )}
    </Modal>
  );
};

export default FAQCategoriesModal;

import { createContext } from 'react';
import usePendingWithdrawApi from './api';

export const PendingWithdrawalsContext = createContext({
  t: () => {},
  user: {},

  fetchWithdrawData: () => {},
  withdrawDataColumnsConfiguration: [],
  withdrawData: [],
  loadedWithdrawData: false,

  withdrawDestinationDataModal: {},
  setWithdrawDestinationDataModal: () => {},
  submittingPayWithdrawModal: false,
  handlePayWithdrawSubmitModal: () => {},
  handlePayWithdrawCloseModal: () => {},
});

export const PendingWithdrawalsProvider = ({ children }) => {
  const value = usePendingWithdrawApi();

  return (
    <PendingWithdrawalsContext.Provider value={value}>
      {children}
    </PendingWithdrawalsContext.Provider>
  );
};

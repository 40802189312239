import {
  Container, Grid, Typography,
} from '@mui/material';
import { useContext, useEffect } from 'react';
import { AdminReportContext, AdminReportProvider } from '../context';
import Layout from '../../../components/CardLayout';
import Table from '../../../components/Table';
import ComponentLoader from '../../../components/ComponentLoader';

const WebinarLeadsReportPage = () => {
  const {
    t,
    fetchWebinarLeadsData,
    webinarLeadsData,
    webinarLeadsDataLoaded,
    webinarLeadsColumnsConfiguration,
  } = useContext(AdminReportContext);

  useEffect(() => {
    fetchWebinarLeadsData();
  }, []);

  return (
    <Container
      maxWidth="xl"
      className="webinar-leads-page"
    >
      <Typography
        sx={{
          display: 'flex',
          justifyContent: 'center',
          fontSize: '26px',
          fontWeight: 600,
          mt: 8,
          mb: 1,
          width: '100%',
        }}
      >
        <span className="primary-text-gradient">{t('Webinar Leads Report')}</span>
      </Typography>
      <Grid
        item
        xl={12}
        lg={12}
        md={12}
        sm={12}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          mt: 6,
        }}
      >
        {webinarLeadsDataLoaded ? (
          <Table
            loading={!webinarLeadsDataLoaded}
            columns={webinarLeadsColumnsConfiguration}
            rows={webinarLeadsData}
            className="table"
          />
        ) : (
          <ComponentLoader />
        )}
      </Grid>
    </Container>
  );
};

export default () => (
  <AdminReportProvider>
    <WebinarLeadsReportPage />
  </AdminReportProvider>
);

/* eslint-disable max-len */
import { useContext } from 'react';
import { AppContext } from '../../../../utils/store/app';

const formConfig = [
  {
    name: 'package',
    type: 'radioGroup',
    template: 'cards',
    label: '',
    options: [],
  },
  // {
  //  name: 'paymentMethod',
  //  type: 'radioGroup',
  //  label: 'Choose Payment Method',
  //  options: [{ label: 'Crypto', value: 'crypto' }],
  // },
];

export const getFormConfig = () => {
  const { packages } = useContext(AppContext);
  // const opts = packages.map((p) => ({ label: `${p.name} ($${p.Price}) ${p.numberOfPasses} ${p?.passesType?.name}`, value: p.id }));
  const opts = packages.filter((pack) => pack.isActive)
    .map((p) => ({ label: { ...p, isBundle: true }, value: p.id }));
  formConfig[0].options = opts;
  formConfig[0].defaultValue = opts[0]?.value;
  return formConfig;
};

export const defaultValue = {};

export default formConfig;

import {
  CircularProgress,
  Container, Grid, Typography,
} from '@mui/material';
import { useContext, useEffect } from 'react';
import { AdminReportContext, AdminReportProvider } from '../context';
import Layout from '../../../components/CardLayout';
import Table from '../../../components/Table';
import ComponentLoader from '../../../components/ComponentLoader';
import Button from '../../../components/Button';
import ResultsBox from '../components/ResultsBox';

const MemeGamerReportPage = () => {
  const {
    t,
    fetchMemeGamerChallengeData,
    memeGamerChallengeData,
    memeGamerChallengeDataLoaded,
    memegamerChallengeColumnsConfiguration,
    memeGamerChallengeDownloadingCSV,
    downloadMemeGamerChallengeReportCSV,
  } = useContext(AdminReportContext);

  useEffect(() => {
    fetchMemeGamerChallengeData();
  }, []);

  return (
    <Container maxWidth="xl" className="usage-report-page">
      <Layout
        maxWidth="100%"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          mt: 6,
        }}
      >
        <Typography
          sx={{
            display: 'flex',
            justifyContent: 'center',
            fontSize: '26px',
            fontWeight: 600,
            mb: 1,
            width: '100%',
          }}
        >
          <span className="primary-text-gradient">{t('Rank Up Challenge Report')}</span>
        </Typography>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <div className="divider" />
        </Grid>

        <div
          className="flex gap-4"
        >
          <Button
            onClick={() => downloadMemeGamerChallengeReportCSV()}
            size="sm"
            bordered
            className="mr-4"
            disabled={memeGamerChallengeDownloadingCSV}
          >
            <span className="no-wrap">{memeGamerChallengeDownloadingCSV ? t('Downloading CSV') : t('Download CSV')}</span>
          </Button>
          {memeGamerChallengeDownloadingCSV && (
            <CircularProgress size={30} />
          )}
        </div>

        <Grid container className="results-container" paddingTop="32px">
          {memeGamerChallengeDataLoaded ? (
            <ResultsBox
              title="Total Gamers"
              result={memeGamerChallengeData.length}
            />
          ) : <ComponentLoader />}
        </Grid>
      </Layout>

      <Grid
        item
        xl={12}
        lg={12}
        md={12}
        sm={12}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          mt: 6,
        }}
      >
        {memeGamerChallengeDataLoaded ? (
          <Table
            loading={!memeGamerChallengeDataLoaded}
            columns={memegamerChallengeColumnsConfiguration}
            rows={memeGamerChallengeData}
            className="table"
            showPagination
          />
        ) : (
          <ComponentLoader />
        )}
      </Grid>
    </Container>
  );
};

export default () => (
  <AdminReportProvider>
    <MemeGamerReportPage />
  </AdminReportProvider>
);

import { useTranslation } from 'react-i18next';
import { getFormConfig } from './config';
import Form from '../../../../components/Form';
import usePurchasePackage from './hook';
// import StripeModal from './components/stripeModal'; // stripe
import BtcModal from './components/modal';

export default function PurchasePackages({ reset }) {
  const { t } = useTranslation();
  const {
    submitPurchase, errors, setErrors, purchaseData,
    handleModalClose, defaultValue, activePackages,
  } = usePurchasePackage(reset);

  return (
    <div>
      {
        activePackages.length > 0 ? (
          <div style={{ paddingTop: 6 }}>
            <h2 className="primary-text-gradient">{t('Purchase Bundles')}</h2>

            <Form
              size="sm"
              config={getFormConfig()}
              onSubmit={submitPurchase}
              submitLabel="Purchase"
              defaultFormValue={defaultValue}
              errors={errors}
              setErrors={setErrors}
              buttonType="submit"
              buttonStyle={{ minWidth: '182px' }}
            />
            {purchaseData && (
              <BtcModal
                data={purchaseData}
                handleModalClose={handleModalClose}
              />
            )}
          </div>
        ) : null
      }

    </div>
  );
}

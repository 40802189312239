import { Container, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Layout from '../../../components/CardLayout';

export default function Coin({ data, onEdit = () => { }, onDelete = () => { } }) {
  return (
    <Layout>
      <Typography
        sx={{
          fontWeight: 600,
          color: 'var(--primary-color)',
          fontSize: '16px',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <span className="primary-text-gradient">{data.name}</span>
        <div style={{
          display: 'flex',
          gap: 3,
        }}
        >
          <EditIcon
            sx={{ cursor: 'pointer' }}
            color="primary"
            onClick={() => {
              onEdit(data);
            }}
          />

          <DeleteIcon
            sx={{ cursor: 'pointer' }}
            color="primary"
            onClick={() => {
              onDelete(data);
            }}
          />
        </div>

      </Typography>
      <div className="divider" />
      <Typography sx={{ opacity: 0.5 }}>{data.description}</Typography>

      <Typography>
        API ID:
        <span className="pl-2">{data.apiId}</span>
      </Typography>
      {
        data.image ? (
          <Container sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <img style={{ height: '70px' }} alt={data.name} src={data.image} />
          </Container>
        ) : ''
      }

    </Layout>
  );
}

/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { CircularProgress, Grid } from '@mui/material';
import { useEffect } from 'react';

export default function PassTypes({
  packages, loading, formTitle, formDescription, selectedPackage, setSelectedPackage,
}) {
  const { t } = useTranslation();
  // const [selectedPackage, setSelectedPackage] = useState(null);

  useEffect(() => {
    if (packages.length >= 1) {
      setSelectedPackage(packages[0].id);
    }
  }, []);

  return (
    <Grid container item className="signup-form admin-login">
      {formTitle && (
        <Grid
          container
          item
          justifyContent="center"
          alignItems="center"
        >
          <h1 className="mb-4 primary-text-gradient">{t(formTitle)}</h1>
        </Grid>
      )}

      {formDescription && (
        <Grid
          container
          item
          justifyContent="center"
          alignItems="center"
        >
          <h4 className="mb-4">{t(formDescription)}</h4>
        </Grid>
      )}

      <Grid container item className="packages mb-8">
        {(loading) && (
          <div style={{ position: 'absolute', top: '50%', left: '50%' }}>
            <CircularProgress />
          </div>
        )}
        {packages.map((p) => (
          <div
            key={p.id}
            className={clsx('package', selectedPackage === p.id && 'selected')}
            onClick={() => setSelectedPackage(p.id)}
          >
            <h3>{p.name}</h3>
            <p>{`$${p.price}`}</p>
          </div>
        ))}
      </Grid>
    </Grid>
  );
}

import { createContext } from 'react';
import useAdminFAQ from './hook';

export const AdminFAQContext = createContext({
  t: () => {},
  user: {},

  faq: {},
  faqsData: {},
  faqsDataLoaded: false,
  faqFormLoading: false,

  getFAQsData: () => {},
  handleOpenModalToAdd: () => {},
  handleOpenModalToEdit: () => {},
  handleCloseModal: () => {},
  handleSubmitFormModal: () => {},

  faqDelete: {},

  handleOpenDeleteModal: () => {},
  handleCloseDeleteModal: () => {},
  handleSubmitDeleteModal: () => {},

  faqFormErrors: {},
  setFAQFormErrors: () => {},

  faqCategoryData: [],
  faqCategoryDataLoaded: false,
  openFAQCategoryModal: false,
  setOpenFAQCategoryModal: () => {},
  faqCategorySingle: { id: '', title: '' },
  setFAQCategorySingle: () => {},
  handleAddCategorySubmit: () => {},

  faqCategoryDelete: {},
  handleOpenFAQCategoryDeleteModal: () => {},
  handleCloseFAQCategoryDeleteModal: () => {},
  handleSubmitDeleteFAQCategoryModal: () => {},
});

export const AdminFAQProvider = ({ children }) => {
  const value = useAdminFAQ();
  return <AdminFAQContext.Provider value={value}>{ children }</AdminFAQContext.Provider>;
};

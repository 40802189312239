/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
import { Tree } from 'react-organizational-chart';
import { Container } from '@mui/material';
import TreeLayout from '../../../../../../components/TreeLayout';
import Tag from '../../../../../../components/Tag';
import TreeNode from './tree-node';
import Node from './node';

export function EJTree({
  users,
  passTypes,
  user,
  fetchSelectedUser,
  currentUser,
  loadingUsersSponsored,
  usersSponsored,
  onlySponsorsToggle,
}) {
  return (
    <TreeLayout
      maxHeight="fit-content"
      className="flex-1 overflow-auto"
      maxWidth="100%"
    >
      <Container
        id="container-tree"
        sx={{
          overflow: 'auto',
          display: 'flex',
          justifyContent: 'center',
          height: '700px',
          width: '100%',
        }}
        maxWidth="100%"
      >
        <Tree
          lineWidth="1px"
          lineColor="#D9D9D9"
          lineStyle="dotted"
          lineBorderRadius="10px"
          label={(
            <div className="flex justify-center cursor-pointer">
              <Tag
                // onClick={onUserNodeClick}
                current
                user={user}
                color="green"
              >
                <Node
                  username={user.Username}
                  displayName={`${user.Name} ${user.Surname}`}
                  dateExpiring={currentUser?.DateExpiring}
                />
              </Tag>
            </div>
          )}
        >
          {onlySponsorsToggle ? (
            !loadingUsersSponsored
            && usersSponsored?.map((usr) => (
              <TreeNode
                passTypes={passTypes}
                onUserNodeClick={(_user) => fetchSelectedUser(_user)}
                key={`sponsored-node-${usr.userId}`}
                user={usr}
                team={0}
                active={usr.active}
                currentUser={currentUser}
                expiration={usr.dateExpiring}
                played={usr.played}
                monetized={usr.monetized}
              />
            ))
          ) : (
            <>
              {users.team1
                && users?.team1?.userId
                && [users.team1].map((usr) => (
                  <TreeNode
                    passTypes={passTypes}
                    onUserNodeClick={(_user) => fetchSelectedUser(_user)}
                    key={`main-node-${usr.userId}`}
                    user={usr}
                    team={1}
                    active={usr.active}
                    currentUser={currentUser}
                    expiration={usr.dateExpiring}
                    played={usr.played}
                    monetized={usr.monetized}
                  />
                ))}

              {users.team2
                && users?.team2?.userId
                && [users.team2].map((usr) => (
                  <TreeNode
                    passTypes={passTypes}
                    onUserNodeClick={(_user) => fetchSelectedUser(_user)}
                    key={`main-node-${usr.userId}`}
                    user={usr}
                    team={2}
                    active={usr.active}
                    currentUser={currentUser}
                    expiration={usr.dateExpiring}
                    played={usr.played}
                    monetized={usr.monetized}
                  />
                ))}

              {users.team3
                && users?.team3?.userId
                && [users.team3].map((usr) => (
                  <TreeNode
                    passTypes={passTypes}
                    onUserNodeClick={(_user) => fetchSelectedUser(_user)}
                    key={`main-node-${usr.userId}`}
                    user={usr}
                    team={3}
                    active={usr.active}
                    currentUser={currentUser}
                    expiration={usr.dateExpiring}
                    played={usr.played}
                    monetized={usr.monetized}
                  />
                ))}
            </>
          )}
        </Tree>
      </Container>
    </TreeLayout>
  );
}

export function NonEjTree({
  users,
  passTypes,
  user,
  fetchSelectedUser,
  currentUser,
  loadingUsersSponsored,
  usersSponsored,
  onlySponsorsToggle,
}) {
  return (
    <TreeLayout
      maxHeight="fit-content"
      className="flex-1 overflow-auto"
      maxWidth="100%"
    >
      <Container
        sx={{
          overflow: 'auto',
          display: 'flex',
          justifyContent: 'center',
          height: '700px',
          width: '100%',
        }}
        maxWidth="100%"
        className="tree-scroll"
      >
        <Tree
          lineWidth="1px"
          lineColor="#D9D9D9"
          lineStyle="dotted"
          lineBorderRadius="10px"
          label={(
            <div className="flex justify-center cursor-pointer">
              <Tag
                // onClick={onUserNodeClick}
                current
                user={user}
                color="gray"
              >
                <Node
                  username={users.sponsor?.username}
                  displayName={users.sponsor?.displayName}
                  dateExpiring={users?.sponsor?.dateExpiring}
                />
              </Tag>
            </div>
          )}
        >
          <Tree
            lineWidth="1px"
            lineColor="#D9D9D9"
            lineStyle="dotted"
            lineBorderRadius="10px"
            label={(
              <div className="flex justify-center cursor-pointer">
                <Tag
                  // onClick={onUserNodeClick}
                  current
                  user={user}
                  color="green"
                >
                  <Node
                    username={user.Username}
                    displayName={`${user.Name} ${user.Surname}`}
                    dateExpiring={currentUser?.DateExpiring}
                  />
                </Tag>
              </div>
            )}
          >
            {onlySponsorsToggle ? (
              !loadingUsersSponsored
                  && usersSponsored?.map((usr) => (
                    <TreeNode
                      passTypes={passTypes}
                      onUserNodeClick={(_user) => fetchSelectedUser(_user)}
                      key={`sponsored-node-${usr.userId}`}
                      user={usr}
                      team={0}
                      active={usr.active}
                      currentUser={currentUser}
                      expiration={usr.dateExpiring}
                      played={usr.played}
                      monetized={usr.monetized}
                    />
                  ))
            ) : (
              <>
                {users.team1
                    && users?.team1?.userId
                    && [users.team1].map((usr) => (
                      <TreeNode
                        passTypes={passTypes}
                        onUserNodeClick={(_user) => fetchSelectedUser(_user)}
                        key={`main-node-${usr.userId}`}
                        user={usr}
                        team={1}
                        active={usr.active}
                        currentUser={currentUser}
                        expiration={usr.dateExpiring}
                        played={usr.played}
                        monetized={usr.monetized}
                      />
                    ))}
      
                {users.team2
                    && users?.team2?.userId
                    && [users.team2].map((usr) => (
                      <TreeNode
                        passTypes={passTypes}
                        onUserNodeClick={(_user) => fetchSelectedUser(_user)}
                        key={`main-node-${usr.userId}`}
                        user={usr}
                        team={2}
                        active={usr.active}
                        currentUser={currentUser}
                        expiration={usr.dateExpiring}
                        played={usr.played}
                        monetized={usr.monetized}
                      />
                    ))}
      
                {users.team3
                    && users?.team3?.userId
                    && [users.team3].map((usr) => (
                      <TreeNode
                        passTypes={passTypes}
                        onUserNodeClick={(_user) => fetchSelectedUser(_user)}
                        key={`main-node-${usr.userId}`}
                        user={usr}
                        team={3}
                        active={usr.active}
                        currentUser={currentUser}
                        expiration={usr.dateExpiring}
                        played={usr.played}
                        monetized={usr.monetized}
                      />
                    ))}
              </>
            )}
          </Tree>
        </Tree>
      </Container>
    </TreeLayout>
  );
}

export default {};

import { createContext } from 'react';
import useFAQ from './hook';

export const FAQContext = createContext({
  t: () => {},
  user: {},

  getFAQsData: () => {},

  faqsData: {},
  faqsDataLoaded: {},
});

export const FAQProvider = ({ children }) => {
  const value = useFAQ();
  return <FAQContext.Provider value={value}>{ children }</FAQContext.Provider>;
};

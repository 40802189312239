import { useContext } from 'react';
import { Typography } from '@mui/material';
import { AdminFAQContext } from '../context';
import Modal from '../../../../components/Modal';
import Button from '../../../../components/Button';

const DeleteFAQCategoryModal = () => {
  const {
    t,
    faqCategoryDelete,
    handleCloseFAQCategoryDeleteModal,
    handleSubmitDeleteFAQCategoryModal,
  } = useContext(AdminFAQContext);

  return (
    <Modal
      title="Delete faq"
      open={!!faqCategoryDelete}
      handleClose={handleCloseFAQCategoryDeleteModal}
      width="600px"
    >
      <Typography fontSize="16px" fontWeight="bold" textAlign="center" marginBottom="8px">
        {t('Are you sure you want to Delete this FAQ Category?')}
      </Typography>
      <Typography textAlign="center" marginBottom="32px">{faqCategoryDelete?.title}</Typography>

      <Button onClick={handleSubmitDeleteFAQCategoryModal}>{t('Delete')}</Button>
    </Modal>
  );
};

export default DeleteFAQCategoryModal;

/* eslint-disable no-restricted-syntax */
import {
  useCallback, useContext, useEffect, useState, 
} from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { AuthenticationContext } from '../../../../utils/store/authentication';
import useApi from '../../../../utils/api';
import { validateAll } from '../../../../components/Form/config';
import { faqFormValidationConfig } from '../config';

const useAdminFAQ = () => {
  const { t } = useTranslation();
  const { user } = useContext(AuthenticationContext);
  const {
    authGet, authPost, authDelete, authPut, 
  } = useApi();
  const [faq, setFAQ] = useState(null);
  const [faqDelete, setFAQDelete] = useState(null);
  const [faqFormErrors, setFAQFormErrors] = useState(null);
  const [faqsData, setFAQsData] = useState([]);
  const [faqsDataLoaded, setFAQsDataLoaded] = useState(false);
  const [faqFormLoading, setFAQFormLoading] = useState(false);
  
  const [faqCategorySingle, setFAQCategorySingle] = useState({ id: '', title: '' });
  const [faqCategoryData, setFAQCategoryData] = useState([]);
  const [faqCategoryDataLoaded, setFAQCategoryDataLoaded] = useState(false);
  const [openFAQCategoryModal, setOpenFAQCategoryModal] = useState(false);
  const [faqCategoryDelete, setFAQCategoryDelete] = useState(null);

  // FAQs
  const getFAQsData = useCallback(
    async () => {
      try {
        setFAQsDataLoaded(false);
        const response = await authGet('/faq');
        setFAQsData(response.data || []);
      } catch (err) {
        console.log(err);
        toast.error('Error fetching faq Data');
      }
      setFAQsDataLoaded(true);
    },
    [authGet],
  );

  const handleOpenModalToAdd = useCallback(
    async () => {
      setFAQ({
        question: '',
        answer: '',
        categoryId: '',
        active: true,
      });
    },
    [setFAQ],
  );

  const handleOpenModalToEdit = useCallback(
    async (faqInfo) => {
      setFAQ(faqInfo);
    },
    [setFAQ],
  );

  const handleCloseModal = useCallback(
    async () => {
      setFAQ(null);
    },
    [setFAQ],
  );

  const handleSubmitFormModal = useCallback(
    async (data) => {
      setFAQFormLoading(true);
      const isEditing = data?.id?.length > 0;

      setFAQFormErrors(null);
      const checkForm = validateAll(data, faqFormValidationConfig);

      // If editing, don't validate the file input
      if (isEditing) {
        delete checkForm.file;
      }
      
      if (checkForm && Object.keys(checkForm).length > 0) {
        setFAQFormErrors(checkForm);
        setFAQFormLoading(false);
        return;
      }

      try {
        if (isEditing) {
          await authPut(`/faq/${data.id}`, { data });
        } else {
          await authPost('/faq', { data });
        }
        
        const successMessage = isEditing
          ? 'FAQ edited successfully.'
          : 'New FAQ has been created.';
        
        toast.success(successMessage);
        handleCloseModal();
        getFAQsData();
      } catch (err) {
        console.log(err);
        toast.error('Error creating FAQ');
      }
      setFAQFormLoading(false);
    },
    [authPost, authPut],
  );
  
  const handleOpenDeleteModal = useCallback(
    async (faqInfo) => {
      setFAQDelete(faqInfo);
    },
    [setFAQDelete],
  );
  
  const handleCloseDeleteModal = useCallback(
    async () => {
      setFAQDelete(null);
    },
    [setFAQDelete],
  );

  const handleSubmitDeleteModal = useCallback(
    async () => {
      try {
        await authDelete(`faq/${faqDelete.id}`);

        toast.success('Deleted Successfully.');

        getFAQsData();
      } catch (err) {
        console.log(err);
      }

      handleCloseDeleteModal();
    },
    [authDelete, faqDelete],
  );

  // FAQ Categories
  const getFAQCategoriesData = useCallback(
    async () => {
      try {
        setFAQCategoryDataLoaded(false);
        const response = await authGet('/faq/category');
        setFAQCategoryData(response.data || []);
      } catch (err) {
        console.log(err);
        toast.error('Error fetching Categories');
      }

      setFAQCategoryDataLoaded(true);
    },
    [authGet],
  );

  const handleAddCategorySubmit = useCallback(
    async (data) => {
      setFAQCategoryDataLoaded(false);

      console.log({ data });

      try {
        // Validate Duplicated Categories
        if (faqCategoryData?.length > 0) {
          const category = faqCategoryData.find((cat) => cat.title.toLowerCase() === data.title.toLowerCase());

          if (category) {
            toast.error('This category already exists.');
            return;
          }
        }
        
        const isEditing = data?.id.length > 0;

        if (isEditing) {
          await authPut(`faq/category/${data?.id}`, { data });
        } else {
          await authPost('faq/category', { data });
        }

        const successMessage = isEditing
          ? 'FAQ Category edited successfully.'
          : 'New FAQ Category has been created.';

        toast.success(successMessage);
        setFAQCategorySingle({ id: '', title: '' });
        getFAQCategoriesData();
      } catch (err) {
        console.log(err);
        toast.error('Error creating Category');
      }

      setFAQCategoryDataLoaded(true);
    },
    [authPost],
  );

  const handleOpenFAQCategoryDeleteModal = useCallback(
    async (categoryInfo) => {
      setFAQCategoryDelete(categoryInfo);
    },
    [setFAQCategoryDelete],
  );

  const handleCloseFAQCategoryDeleteModal = useCallback(
    async () => {
      setFAQCategoryDelete(null);
    },
    [setFAQCategoryDelete],
  );

  const handleSubmitDeleteFAQCategoryModal = useCallback(
    async () => {
      try {
        await authDelete(`faq/category/${faqCategoryDelete.id}`);

        toast.success('Deleted Successfully.');

        getFAQCategoriesData();
      } catch (err) {
        console.log(err);
      }
      
      setFAQCategorySingle({ id: '', title: '' });
      handleCloseFAQCategoryDeleteModal();
    },
    [authDelete, faqCategoryDelete],
  );

  useEffect(() => {
    getFAQsData();
    getFAQCategoriesData();
  }, []);

  return {
    t,
    user,

    faq,
    faqsData,
    faqsDataLoaded,
    faqFormLoading,
    
    getFAQsData,
    handleOpenModalToAdd,
    handleOpenModalToEdit,
    handleCloseModal,
    handleSubmitFormModal,

    faqDelete,

    handleOpenDeleteModal,
    handleCloseDeleteModal,
    handleSubmitDeleteModal,

    faqFormErrors,
    setFAQFormErrors,

    faqCategoryData,
    faqCategoryDataLoaded,
    openFAQCategoryModal,
    setOpenFAQCategoryModal,
    
    faqCategorySingle,
    setFAQCategorySingle,
    handleAddCategorySubmit,

    faqCategoryDelete,
    handleOpenFAQCategoryDeleteModal,
    handleCloseFAQCategoryDeleteModal,
    handleSubmitDeleteFAQCategoryModal,
  };
};

export default useAdminFAQ;

import useAdminReportApi from './api';

const useAdminReportHook = () => {
  const {
    t,
    user,
    usageData,
    loadedUsageData,
    usageDataPagination,
    fetchUsageData,
    fetchSalesData,
    salesData,
    loadedSalesData,
    salesColumnsConfiguration,
    usageDataColumnsConfiguration,
    downloadingCsv,
    downloadUsageReportCsv,
    
    fetchGameResultsData,
    gameResultsColumnsConfiguration,
    gameResultsData,
    gameResultsDataLoaded,
    gameResultsDataPagination,

    fetchGameResultsCondensedData,
    gameResultsCondensedData,
    gameResultsCondensedDataLoaded,
    setGameSingleResultData,
    gameSingleResultData,
    gameSingleResultColumnsConfiguration,

    fetchWebinarLeadsData,
    webinarLeadsData,
    webinarLeadsDataLoaded,
    webinarLeadsColumnsConfiguration,

    fetchMemeGamerChallengeData,
    memeGamerChallengeData,
    memeGamerChallengeDataLoaded,
    memegamerChallengeColumnsConfiguration,
    memeGamerChallengeDownloadingCSV,
    downloadMemeGamerChallengeReportCSV,
  } = useAdminReportApi();

  return {
    t,
    user,
    usageData,
    loadedUsageData,
    usageDataPagination,
    fetchUsageData,
    fetchSalesData,
    salesData,
    loadedSalesData,
    salesColumnsConfiguration,
    usageDataColumnsConfiguration,
    downloadingCsv,
    downloadUsageReportCsv,
    
    fetchGameResultsData,
    gameResultsColumnsConfiguration,
    gameResultsData,
    gameResultsDataLoaded,
    gameResultsDataPagination,

    fetchGameResultsCondensedData,
    gameResultsCondensedData,
    gameResultsCondensedDataLoaded,
    setGameSingleResultData,
    gameSingleResultData,
    gameSingleResultColumnsConfiguration,

    fetchWebinarLeadsData,
    webinarLeadsData,
    webinarLeadsDataLoaded,
    webinarLeadsColumnsConfiguration,

    fetchMemeGamerChallengeData,
    memeGamerChallengeData,
    memeGamerChallengeDataLoaded,
    memegamerChallengeColumnsConfiguration,
    memeGamerChallengeDownloadingCSV,
    downloadMemeGamerChallengeReportCSV,
  };
};

export default useAdminReportHook;

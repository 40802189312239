export const transferCoinsFormConfig = [
  {
    name: 'toUserId',
    label: 'To Gamer',
    type: 'autocomplete',
    endPoint: '/users',
    qs: 'sponsor=1',
    mapper: (val) => ({ label: `${val.Name} ${val.Surname} (${val.Username})`, value: val }),
  },
  {
    name: 'amount',
    label: 'Amount',
    type: 'number',
  },
];

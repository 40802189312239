/* eslint-disable no-restricted-syntax */
/* eslint-disable max-len */
import { useContext, useEffect, useState } from 'react';
// import { changeLanguage } from 'i18next';
import OthersCont from '../OthersCont';
import { Context } from '../../../context';
import { SocketContext } from '../../../../../utils/store/socket';

export default function TodayOtherCoins({
  game,
  coins,
  isMobile,
  user,
  currentVoteIndex,
  setCurrentVoteIndex,
}) {
  const { chosenMemeCoin: chosenMemeCoins } = useContext(Context);
  const chosenMemeCoin = chosenMemeCoins.find((c) => c.gameId === game.id);
  const isTop10 = game?.statistics?.rankingPercentage <= 10;
  const percentageChanged = game?.statistics?.change;
  const socket = useContext(SocketContext);
  const leaderboardRank = game?.statistics?.rankingPercentage;
  const [leadRank, setLeadRank] = useState(leaderboardRank || 0);
  const [myPercentChange, setMyPercentChange] = useState(percentageChanged || 0);
  const [leader, setLeader] = useState(game.leader);
  const [votes, setVotes] = useState(game?.votes || {});

  useEffect(() => {
    const voteSoks = [];

    if (game) {
      const _votes = game?.votes || {};

      socket.on(`game:${game.id}:user:${user.SID}:graph`, (data) => {
        setLeadRank(data.leaderboardRank);
        setMyPercentChange(data.myPercentChange);
      });

      if (Object.entries(_votes).length >= 1) {
        for (const [voteId] of Object.entries(_votes)) {
          socket.on(`game:${game.id}:user:${user.SID}:${voteId}:graph`, (data) => {
            _votes[voteId].stats = data;
          });
          voteSoks.push(`game:${game.id}:user:${user.SID}:${voteId}:graph`);
        }
        setVotes(_votes);
      }

      socket.on(`game:leaderboard:updated:${game.id}`, (data) => {
        setLeader(data.leader);
      });
    }
    return () => {
      socket.off(`game:${game.id}:user:${user.SID}:graph`);
      socket.off(`game:leaderboard:updated:${game.id}`);
      for (const voteSok of voteSoks) {
        socket.off(voteSok);
      }
    };
  }, [socket, game, leadRank, myPercentChange, leader]);

  return (
    <OthersCont
      user={user}
      game={game}
      leader={leader}
      chosenMemeCoin={chosenMemeCoin}
      coins={coins}
      isTop10={isTop10}
      percentageChange={myPercentChange}
      leaderboardRank={leadRank}
      isMobile={isMobile}
      votes={votes}
      currentVoteIndex={currentVoteIndex}
      setCurrentVoteIndex={setCurrentVoteIndex}
    />
  );
}

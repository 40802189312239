import { useContext, useEffect, useState } from 'react';
import { IconButton, Tooltip } from '@mui/material';
import Button from '../../../../components/Button';
import TextInput from '../../../../components/Inputs/TextInput';
import Modal from '../../../../components/Modal';
import { PendingWithdrawalsContext } from '../context';

const PayWithdrawModal = () => {
  const [transactionId, setTransactionId] = useState('');

  const {
    submittingPayWithdrawModal,
    withdrawDestinationDataModal,
    handlePayWithdrawSubmitModal,
    handlePayWithdrawCloseModal,
  } = useContext(PendingWithdrawalsContext);

  useEffect(() => {
    setTransactionId('');
  }, [withdrawDestinationDataModal]);

  return (
    <Modal
      title="Mark as Paid"
      width="550px"
      open={!!withdrawDestinationDataModal}
      handleClose={handlePayWithdrawCloseModal}
    >
      <Tooltip placement="top" title="Click to Copy">
        <button
          type="button"
          className="text-center mb-4"
          style={{
            display: 'block',
            cursor: 'pointer',
            background: 'none',
            border: 'none',
            color: 'white',
            margin: '0 auto 24px',
            fontWeight: '600',
          }}
          onClick={async () => {
            await navigator.clipboard.writeText(
              withdrawDestinationDataModal?.destination,
            );
          }}
        >
          {withdrawDestinationDataModal?.destination}
        </button>
      </Tooltip>
      <TextInput
        value={transactionId}
        onChange={(e) => {
          setTransactionId(e);
        }}
        placeholder="Transaction Id"
        className="mb-5"
      />
      <Button
        onClick={() => {
          if (!submittingPayWithdrawModal && !!transactionId) {
            handlePayWithdrawSubmitModal(transactionId);
          }
        }}
        className="mb-5"
        disabled={submittingPayWithdrawModal || !transactionId}
      >
        <span className="no-wrap">Paid</span>
      </Button>
    </Modal>
  );
};

export default PayWithdrawModal;

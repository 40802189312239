import { Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import Layout from '../../../../components/CardLayout';

export default function PackageCard({ data, onPackageClick = () => { } }) {
  return (
    <Layout>
      <Typography
        sx={{
          fontWeight: 600,
          fontSize: '16px',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <span className="primary-text-gradient">{data.name}</span>
        <EditIcon
          sx={{ cursor: 'pointer' }}
          color="primary"
          onClick={() => {
            onPackageClick(data);
          }}
        />
      </Typography>
      <div className="divider" />
      <Typography sx={{ opacity: 0.5 }}>{data?.description || '-'}</Typography>
      <Typography>
        Pack Type :
        <span className="pl-2">{data?.passesType?.name}</span>
      </Typography>
      <Typography>
        Price :
        <span className="pl-2">
          $
          {Number(data.Price).toLocaleString('en-us')}
        </span>
      </Typography>
      <Typography>
        Nr. of Packs :
        <span className="pl-2">{data?.numberOfPasses || 0}</span>
      </Typography>
      <Typography
        sx={{
          color: data.isActive ? 'green' : 'red',
          position: 'absolute',
          bottom: '10px',
          right: '10px',
        }}
      >
        <span className="pl-2">{`${data.isActive ? 'Active' : 'Inactive'}`}</span>
      </Typography>
    </Layout>
  );
}

/* eslint-disable jsx-a11y/label-has-associated-control */
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  CircularProgress, Box, Typography,
} from '@mui/material';
import { columnsConfig } from './config';
import Table from '../../../../components/Table';
import { PAGE_SIZE } from '../../api';
import Button from '../../../../components/Button';
import TransferPassesModal from './components/transfer/TransferPassesModal';
import Select from '../../../../components/Inputs/Select';
import useRenewMembership from '../RenewMemberships/hook';
import Modal from '../../../../components/Modal';
import TablePagination from '../../../../components/Table/Pagination';
import TableMobile from '../../../../components/Table/TableMobile';

export default function Passes({
  data = [], loading, passesTotal, fetchPasses, passesPage,
  passTypes, fetchTransfers, setPassesLoading, passesTotalAvailable, refresh,
}) {
  const { t } = useTranslation();
  const { submitRenew } = useRenewMembership(refresh);
  const [activateClicked, setActivateClicked] = useState();

  const onActivateClick = (_data) => {
    setActivateClicked(_data);
  };

  const totalPages = useMemo(() => Math.ceil(passesTotal / PAGE_SIZE), [passesTotal]);
  const [transferData, setTransferData] = useState();
  const [errors, setErrors] = useState();
  const [filters, setFilters] = useState({ page: passesPage });
  const [selectedFilters, setSelectedFilters] = useState(1);

  const onTransferPasses = useCallback((val) => {
    setTransferData({
      ...val,
      passTypes,
      passesTotal,
    });
  }, [passTypes, passesTotal]);

  const activePassTypes = useMemo(() => passTypes.filter((p) => p.active), [passTypes]);

  const columns = useMemo(() => (
    columnsConfig(t, onActivateClick, onTransferPasses)
  ), [t, onActivateClick, onTransferPasses]);

  const handleFilterChange = (selectedFilter) => {
    setPassesLoading(true);
    if (selectedFilter?.used === 'default' || selectedFilter?.used === 'all') {
      selectedFilter.used = undefined;
    }
    if (selectedFilter?.passTypeId === 'default' || selectedFilter?.passTypeId === 'all') {
      selectedFilter.passTypeId = undefined;
    }

    setFilters({ ...filters, ...selectedFilter });
    fetchPasses({ page: 1, ...filters, ...selectedFilter });

    if (!Object.keys(filters).includes('used')) {
      setSelectedFilters(selectedFilters + 1);
    }

    setPassesLoading(false);
  };

  const handleModalClose = async () => {
    try {
      setTransferData(null);
      setErrors(null);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="coin-packs-card">
      <div className="packs-head">
        <h1>{t('Coin Packs')}</h1>
        <span>{`${passesTotalAvailable} Available`}</span>
      </div>

      <div className="flex packs-filters">
        <div className="flex flex-1">
          <Select
            className="transfers-filter-select"
            placeholder="Used"
            variant="outlined"
            size="sm"
            value={filters?.used || 'default'}
            onChange={(v) => {
              handleFilterChange({ used: v });
            }}
            options={[
              { value: 'all', label: 'All' },
              { value: 'true', label: 'Used' },
              { value: 'false', label: 'Not Used' },
            ]}
          />

          <Select
            className="transfers-filter-select"
            placeholder="Type"
            size="sm"
            value={filters?.passTypeId || 'default'}
            onChange={(v) => handleFilterChange({ passTypeId: v })}
            options={activePassTypes.map((p) => ({ value: p.id, label: p.name }))}
          />
        </div>

        <div className="desktop">
          <TablePagination
            count={totalPages || 0}
            page={passesPage || 1}
            onChange={(page) => {
              fetchPasses({ ...filters, page });
            }}
          />
        </div>
        {/* <Button
          size="sm"
          className="ml-4"
          onClick={(e) => onTransferPasses(e)}
        >
          {t('Transfer Coin Packs')}
        </Button> */}
      </div>

      <div className="table-cont">
        {loading ? (
          <div className="flex justify-center">
            <CircularProgress />
          </div>
        ) : (
          <>
            <div className="desktop">
              <Table loading={loading} columns={columns} rows={data} className="table mb-4" />
            </div>
            <div className="mobile">
              <TableMobile columns={columns} data={data} className="mb-6" />
              <TablePagination
                isMobile
                count={totalPages || 0}
                page={passesPage || 1}
                onChange={(page) => {
                  fetchPasses({ ...filters, page });
                }}
              />
            </div>
          </>
        )}
      </div>

      {transferData && (
        <TransferPassesModal
          errors={errors}
          submit=""
          data={transferData}
          handleModalClose={handleModalClose}
          fetchPasses={fetchPasses}
          fetchTransfers={fetchTransfers}
        />
      )}

      {activateClicked ? (
        <Modal title="Confirmation Modal" width={500} open={!!activateClicked}>
          <Box>
            <Typography sx={{ width: '100%', textAlign: 'center', mb: 4 }}>
              Are you sure you want to activate
              {' '}
              {activateClicked.pass}
              {' '}
              {' '}
              ?
            </Typography>
            <Box sx={{ display: 'flex', gap: 3 }}>
              <Button style={{ width: '100%' }} onClick={() => { submitRenew(activateClicked); setActivateClicked(); }}>
                Activate
              </Button>
              <Button style={{ width: '100%' }} onClick={() => setActivateClicked()}>
                Cancel
              </Button>
            </Box>
          </Box>
        </Modal>
      ) : ''}
    </div>
  );
}

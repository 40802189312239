import { Alert } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Modal from '../../../../components/Modal';
import Form from '../../../../components/Form';

export default function RankModal({
  errors,
  data,
  handleModalClose,
  submit,
  setError,
  message,
  formLoading,
  formConfig,
}) {
  const { t } = useTranslation();

  return (
    <Modal
      title={data?._id ? 'Edit Rank' : 'Create Rank'}
      width="550px"
      open={!!data}
      handleClose={handleModalClose}
    >
      {message && (
        <Alert sx={{ mb: '15px' }} className={message.type} severity={message.type}>
          {t(message.label)}
        </Alert>
      )}
      <Form
        errors={errors}
        setError={setError}
        defaultFormValue={data}
        config={formConfig}
        size="sm"
        className=""
        submitLabel="Submit"
        onSubmit={submit}
        formLoading={formLoading}
        buttonStyle={{ minWidth: '182px' }}
        buttonType="button"
      />
      {' '}
    </Modal>
  );
}

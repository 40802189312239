export const defaultFormValues = {};

export const formConfig = [
  {
    name: 'name',
    label: 'Name',
    type: 'text',
  },
  {
    name: 'rank',
    label: 'Rank',
    type: 'number',
  },
  {
    name: 'dailyPay',
    label: 'Daily Pay',
    type: 'number',
  },
  {
    name: 'yearlyEarning',
    label: 'Yearly Earning',
    type: 'number',
  },
  {
    name: 'minTv',
    label: 'Minimal Team Volume',
    type: 'number',
  },
  {
    name: 'maxTv',
    label: 'Max Team Volume',
    type: 'number',
  },
  {
    name: 'description',
    label: 'Description',
    type: 'textarea',
  },

];

export const getFormConfig = () => formConfig;

export const formValidationConfig = {
  name: { isEmpty: false, type: 'String', message: 'Please do not leave this field empty!' },
  rank: { isEmpty: false, type: 'Number', message: 'Please do not leave this field empty!' },
};

export default {};

/* eslint-disable import/no-named-as-default */
import {
  Routes, Route, BrowserRouter, Navigate,
} from 'react-router-dom';
import Payments from '../features/Payments';
import PublicRoute from './publicRoute';
import ProtectedRoute from './protectedRoute';
import Login from '../features/Authentication/Login';
import SignUp from '../features/Authentication/SignUp';
import PayNow from '../features/Authentication/PayNow';
import GetParent from '../features/Authentication/GetParent';
import LoginAdmin from '../features/Authentication/LoginAdmin';
import SignupSuccess from '../features/Authentication/SignupSucces';
import ThankYouPage from '../features/Authentication/ThankYou';
import UserExists from '../features/Authentication/UserExists';
import SignupForm from '../features/Authentication/SignupForm';
import VerifyEmailConfirm from '../features/Authentication/VerifyEmail';
import TokenExpired from '../features/Authentication/TokenExpired';
import PaymentStatus from '../features/Authentication/PaymentStatus';
import LandingPage from '../features/LandingPage';
import AppLayout from '../components/AppLayout';
import Home from '../features/Home';
import Profile from '../features/Profile';
import AdminRoutes from './adminRoutes';
import AdminMembers from '../features/Admin/Members';
import AdminPasses from '../features/Admin/Passes';
import Ranks from '../features/Admin/Ranks';
import PassTypes from '../features/Admin/PassTypes';
import Packages from '../features/Admin/Packages';
import LoginForm from '../features/Authentication/LoginEmail';
import AffiliateRoute from './affiliateRoutes';
import Wallets, { WalletRedirect } from '../features/Wallets';
import Memberships from '../features/Memberships';
import { useAuthentication } from '../utils/store';
import Tree from '../features/Sales/Genealogy';
import List from '../features/Sales/List';
import LeaderboardPage from '../features/Leaderboard';
import GameHistoryPage from '../features/GameHistory';
import ResetPassword from '../features/Authentication/NewPassword';
import ForgotPassword from '../features/Authentication/ForgotPassword';
import SignIn from '../features/Authentication/SignIn';
import MemeGames from '../features/MemeGames';
import MemeCoins from '../features/MemeCoins';
import FindUsername from '../features/Authentication/FindUsername';
import CompPlan from '../features/CompPlan';
import Rules from '../features/Rules';
import UsageReportPage from '../features/AdminReports/UsageReport';
import SalesReportPage from '../features/AdminReports/SalesReport';
import HistoryDescriptions from '../features/Admin/HistoryDescriptions';
import RedeemTokenPage from '../features/RedeemToken';
import GamificationWebinarPage from '../features/GamificationWebinar';
import GamePayouts from '../features/Admin/GamePayouts';
import SendEmail from '../features/Admin/SendEmail';
import PendingWithdrawals from '../features/Admin/PendingWithdrawals';
// import ChatBot from '../features/ChatBot';
import IntroVideo from '../features/Resources/IntroVideo';
import Podcast from '../features/Resources/Podcast';
import AdminPodcast from '../features/Admin/AdminPodcast';
import PendingRedemptions from '../features/Admin/PendingRedemptions';
import GameResultsReportPage from '../features/AdminReports/GameResultsReport';
import WebinarLeadsReportPage from '../features/AdminReports/WebinarLeadsReport';
import FreeTrialPage from '../features/FreeTrialPage';
import SiteTitleChanger from './SiteTitleChanger';
import MemeGamerChallengePage from '../features/MemeGamerChallenge';
import MemeGamerChallengePublic from '../features/MemeGamerChallengePublic';
import BoosterLeadAdminPage from '../features/Admin/BoosterLeadAdmin';
import BoosterLeadPage from '../features/BoosterLeadPage';
import GlobalBannerPage from '../features/Admin/GlobalBanner';
import AdminFAQPage from '../features/Admin/AdminFAQ';
import FAQPage from '../features/Resources/FAQ';
import MemeGamerReportPage from '../features/AdminReports/MemeGamerReport';

export default function Navigation() {
  const { user } = useAuthentication();

  return (
    <BrowserRouter basename={process.env.REACT_APP_URL_BASE_NAME || ''}>
      <SiteTitleChanger />

      <Routes>

        <Route path="/*" element={<Navigate to="/onboarding" />} />
        <Route path="/" element={<Navigate to="/onboarding" />} />

        <Route
          path="/onboarding"
          element={(
            <PublicRoute>
              <LandingPage />
            </PublicRoute>
          )}
        />

        <Route
          path="/webinar"
          element={(
            <PublicRoute>
              <GamificationWebinarPage />
            </PublicRoute>
          )}
        />

        <Route
          path="/rank-up-challenge"
          element={(
            <PublicRoute>
              <MemeGamerChallengePublic />
            </PublicRoute>
          )}
        />

        <Route
          path="/freetrial"
          element={(
            <PublicRoute>
              <FreeTrialPage />
            </PublicRoute>
          )}
        />

        <Route
          path="/"
          element={
            user ? (
              <ProtectedRoute>
                <AppLayout>
                  <Home />
                </AppLayout>
              </ProtectedRoute>
            ) : (
              <PublicRoute>
                <LandingPage />
              </PublicRoute>
            )
          }
        />

        <Route
          path="/dashboard/signin"
          element={(
            <PublicRoute>
              <Login />
            </PublicRoute>
          )}
        />

        <Route
          path="/signin"
          element={(
            <PublicRoute>
              <SignIn />
            </PublicRoute>
          )}
        />
        <Route
          path="/login"
          element={(
            <PublicRoute>
              <LoginForm />
            </PublicRoute>
          )}
        />

        <Route
          path="/payment-status"
          element={(
            <PublicRoute>
              <PaymentStatus />
            </PublicRoute>
          )}
        />
        <Route
          path="/verify-email"
          element={(
            <PublicRoute>
              <VerifyEmailConfirm />
            </PublicRoute>
          )}
        />

        <Route
          path="/reset-password"
          element={(
            <PublicRoute>
              <ForgotPassword />
            </PublicRoute>
          )}
        />

        <Route
          path="/find-username"
          element={(
            <PublicRoute>
              <FindUsername />
            </PublicRoute>
          )}
        />

        <Route
          path="/new-password"
          element={(
            <PublicRoute>
              <ResetPassword />
            </PublicRoute>
          )}
        />

        <Route
          path="/token-expired"
          element={(
            <PublicRoute>
              <TokenExpired />
            </PublicRoute>
          )}
        />

        <Route
          path="/signup/form"
          element={(
            <PublicRoute>
              <SignupForm />
            </PublicRoute>
          )}
        />

        <Route
          path="/thank-you"
          element={(
            <PublicRoute>
              <ThankYouPage />
            </PublicRoute>
          )}
        />

        <Route
          path="/user-exists"
          element={(
            <PublicRoute>
              <UserExists />
            </PublicRoute>
          )}
        />

        <Route
          path="/dashboard/registration/:step"
          element={(
            <PublicRoute>
              <SignUp />
              {/* <LandingPage /> */}
            </PublicRoute>
          )}
        />

        <Route
          path="/dashboard/enroll-now/pay"
          element={(
            <PublicRoute>
              <PayNow />
            </PublicRoute>
          )}
        />

        <Route
          path="/renew-account"
          element={(
            <ProtectedRoute>
              <PayNow />
            </ProtectedRoute>
          )}
        />

        <Route
          path="/dashboard/enroll-now/success"
          element={(
            <PublicRoute>
              <SignupSuccess />
            </PublicRoute>
          )}
        />

        <Route
          path="/e/:username"
          element={(
            <PublicRoute>
              <GetParent />
            </PublicRoute>
          )}
        />

        <Route
          path="/admin/signin"
          element={(
            <PublicRoute>
              <LoginAdmin />
            </PublicRoute>
          )}
        />

        <Route path="/payments/final" element={<Payments />} />

        <Route
          path="/"
          element={(
            <ProtectedRoute>
              <AppLayout />
            </ProtectedRoute>
          )}
        >

          <Route path="/rank-up-rules" element={<MemeGamerChallengePage />} />
          <Route path="/game-rules" element={<Rules />} />
          <Route path="/home" element={<Home />} />
          <Route path="/profile" element={<Profile />} />
          {/* <Route path="/payment-success" element={<PaymentSuccess />} /> */}

          <Route
            path="/wallets"
            element={(
              <WalletRedirect user={user} />
            )}
          />

          <Route
            path="/gamer-wallets"
            element={(
              <Wallets isGamer />
            )}
          />

          <Route
            path="/ambassador-wallets"
            element={(
              <AffiliateRoute isAffiliate>
                <Wallets />
              </AffiliateRoute>
            )}
          />

          <Route
            path="/packages"
            element={(
              // <AffiliateRoute isAffiliate={false} isProduct>
              <Memberships />
              // </AffiliateRoute>
            )}
          />

          <Route
            path="/comp-plan"
            element={(
              <AffiliateRoute isAffiliate>
                <CompPlan />
              </AffiliateRoute>
            )}
          />

          <Route
            path="/intro-video"
            element={(
              <IntroVideo />
            )}
          />

          <Route
            path="/podcast"
            element={(
              <Podcast />
            )}
          />

          <Route
            path="/faqs"
            element={(
              <FAQPage />
            )}
          />

          {/* <Route
            path="/sales"
            element={(
              <AffiliateRoute isProduct isAffiliate={false}>
                <List />
              </AffiliateRoute>
            )}
          /> */}

          <Route
            path="/sales/tree"
            element={(
              <AffiliateRoute isProduct isAffiliate={false}>
                <Tree />
              </AffiliateRoute>
            )}
          />

          <Route
            path="/sales/list"
            element={(
              <AffiliateRoute isProduct isAffiliate={false}>
                <List />
              </AffiliateRoute>
            )}
          />

          <Route
            path="/leaderboard"
            element={(
              <LeaderboardPage />
            )}
          />

          <Route
            path="/game-history"
            element={(
              <GameHistoryPage />
            )}
          />

          <Route
            path="/redeem-token"
            element={(
              <RedeemTokenPage />
            )}
          />

          <Route
            path="/booster-leads"
            element={(
              <AffiliateRoute isAffiliate>
                <BoosterLeadPage />
              </AffiliateRoute>
            )}
          />

          <Route element={<AdminRoutes />}>
            <Route path="/admin/members" element={<AdminMembers />} />
            <Route path="/admin/passes" element={<AdminPasses />} />
            <Route path="/admin/ranks" element={<Ranks />} />
            <Route path="/admin/pass-types" element={<PassTypes />} />
            <Route path="/admin/packages" element={<Packages />} />
            <Route path="/admin/tree" element={<Tree />} />
            <Route path="/admin/meme-coins" element={<MemeCoins />} />
            <Route path="/admin/meme-games" element={<MemeGames />} />
            <Route path="/admin/reports/usage" element={<UsageReportPage />} />
            <Route path="/admin/reports/sales-report" element={<SalesReportPage />} />
            <Route path="/admin/reports/game-results" element={<GameResultsReportPage />} />
            <Route path="/admin/reports/webinar" element={<WebinarLeadsReportPage />} />
            <Route path="/admin/reports/challenge" element={<MemeGamerReportPage />} />
            <Route path="/admin/descriptions-history" element={<HistoryDescriptions />} />
            <Route path="/admin/payouts" element={<GamePayouts />} />
            <Route path="/admin/send-email" element={<SendEmail />} />
            <Route path="/admin/pod-cast" element={<AdminPodcast />} />
            <Route path="/admin/pending-withdrawals" element={<PendingWithdrawals />} />
            <Route path="/admin/pending-redemptions" element={<PendingRedemptions />} />
            <Route path="/admin/booster-program" element={<BoosterLeadAdminPage />} />
            <Route path="/admin/global-banner" element={<GlobalBannerPage />} />
            <Route path="/admin/faq" element={<AdminFAQPage />} />
          </Route>
          <Route path="/admin/members/:id" element={<AdminMembers />} />
        </Route>
      </Routes>

    </BrowserRouter>
  );
}

/* eslint-disable no-nested-ternary */
import {
  useEffect, useState,
} from 'react';
import { Grid } from '@mui/material';
import Modal from '../../../components/Modal';
import useApi from '../../../utils/api';

export default function AdminModal({
  openAdminModal, onClose, game, username,
}) {
  const { authGet } = useApi();
  const [lb, setLb] = useState(null);
  const [loading, setLoading] = useState(false);
  const coin1Name = game?.coinOne?.apiId || game?.memeCoins?.coinOne?.apiId;
  const coin2Name = game?.coinTwo?.apiId || game?.memeCoins?.coinTwo?.apiId;
  const price = game?.price || game?.scheduleGameId?.price;
  // const change = game?.change || game?.scheduleGameId?.change;

  const getLeaderboard = async () => {
    setLoading(true);
    const gameId = game?.id || game?.scheduleGameId?.id;
    const res = await authGet(`schedule-game/leaderboard/${gameId}`);
    setLb(res);
    setLoading(false);
  };

  useEffect(() => {
    getLeaderboard();
  }, [game]);

  return (
    <Modal
      title={`Game Info (${price})`}
      open={openAdminModal}
      handleClose={onClose}
      width="700px"
    >
      <Grid container spacing={3} className="chatgpt-modal">
        {!loading && (
          <>
            <Grid item sm={6}>
              <Grid item sm={12} style={{ marginBottom: '10px' }}>
                <span style={{
                  fontSize: '22px', fontWeight: '500', borderBottom: '1px solid',
                }}
                >
                  {coin1Name}
                </span>
              </Grid>
              {(lb && Object.entries(lb).length >= 1 && lb[coin1Name]) && lb[coin1Name].map((item, index) => (
                <Grid item container spacing={3} key={index}>
                  <Grid item sm={12}>
                    <span
                      style={{
                        color: `${item?.rankingPercentage < 10 ? '#6ce46c' : username === item?.username ? '#fcdd86' : ''}`,
                      }}
                    >
                      {`${index + 1}. (${item?.rankingPercentage}%) - ${item?.username || item?.user} (${item?.change?.toFixed(2)}%)`}
                    </span>
                    <span className="primary-color bold fz-small">{`${item?.locked ? ' L' : ''}`}</span>
                  </Grid>
                </Grid>
              ))}
            </Grid>
            <Grid item sm={6}>
              <Grid item sm={12} style={{ marginBottom: '10px' }}>
                <span style={{
                  fontSize: '22px', fontWeight: '500', borderBottom: '1px solid',
                }}
                >
                  {coin2Name}
                </span>
              </Grid>
              {(lb && Object.entries(lb).length >= 1 && lb[coin2Name]) && lb[coin2Name].map((item, index) => (
                <Grid item container spacing={3} key={index}>
                  <Grid item>
                    <span
                      style={{
                        color: `${item?.rankingPercentage < 10 ? '#6ce46c' : username === item?.username ? '#fcdd86' : ''}`,
                      }}
                    >
                      {`${index + 1} (${item?.rankingPercentage}%) - ${item?.username || item?.user} (${item?.change?.toFixed(2)}%)`}
                    </span>
                    <span className="primary-color bold fz-small">{`${item?.locked ? ' L' : ''}`}</span>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </>

        )}

      </Grid>
    </Modal>

  );
}

import { useNavigate } from 'react-router-dom';
import { Container, Grid, Typography } from '@mui/material';
import { useContext } from 'react';
import Layout from '../../../components/CardLayout';
import ComponentLoader from '../../../components/ComponentLoader';
import { GlobalBannerContext, GlobalBannerProvider } from './context';
import AddEditBannerModal from './components/AddEditBannerModal';
import BannersListRow from './components/BannersListRow';
import DeleteBannerModal from './components/DeleteBannerModal';

const GlobalBannerPage = () => {
  const { navigate } = useNavigate();
  const {
    t,
    user,

    allBanners,
    loadedAllBanners,
  } = useContext(GlobalBannerContext);

  if (!user) {
    navigate('/landing');
  }

  return (
    <Container className="admin-notification-page">
      <Layout
        maxWidth="100%"
        sx={{
          mt: 6,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <Typography
          sx={{
            display: 'flex',
            justifyContent: 'center',
            fontSize: '26px',
            fontWeight: 600,
            width: '100%',
          }}
        >
          <span className="primary-text-gradient">
            {t('Global Banner')}
          </span>
        </Typography>

        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <div className="divider" />
        </Grid>

        <Grid
          item
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={12}
          sx={{ display: 'flex', justifyContent: 'center', paddingTop: '14px' }}
        >
          <AddEditBannerModal />
          <DeleteBannerModal />
        </Grid>
      </Layout>
      <Grid
        xl={12}
        lg={12}
        md={12}
        xs={12}
        sm={12}
        item
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          mt: 6,
        }}
      >
        {!loadedAllBanners && <ComponentLoader />}
        {loadedAllBanners && (allBanners.length > 0 ? (
          allBanners.map((banner, idx) => (
            <BannersListRow key={idx} banner={banner} />
          ))
        ) : (
          <p>There are not banners to show.</p>
        ))}
      </Grid>
    </Container>
  );
};

export default () => (
  <GlobalBannerProvider>
    <GlobalBannerPage />
  </GlobalBannerProvider>
);

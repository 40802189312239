/* eslint-disable indent */
import { useContext, useEffect, useState } from 'react';
import { Grid, Slider, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../../../utils/store/app';
import Modal from '../../../components/Modal';
import Button from '../../../components/Button';
import { renderNumber } from '../../../utils/string';
import { RedeemTokenContext } from '../context';
import RadioGroup from '../../../components/Inputs/RadioGroup';

const redeemIncrements = 1350;

const RedeemCoinPackModal = () => {
  const {
    t,
    user,
    loadedWalletData,
    walletData,
    handleRedeemCoinPackSubmit,
  } = useContext(RedeemTokenContext);
  const navigate = useNavigate();

  const { passTypes } = useContext(AppContext);

  const filteredPassTypes = [
    ...passTypes.filter((type) => (type.price === 135 || type.name?.includes('Rank Up Challenge')) && type.active),
  ];

  const [redeemAmount, setRedeemAmount] = useState(redeemIncrements);
  const [minRedeemAmount, setMinRedeemAmount] = useState(redeemIncrements);

  const [selectedPassTypeId, setSelectedPassTypeId] = useState(null);
  const [selectedIsBundle, setSelectedIsBundle] = useState(false);

  const [openModal, setOpenModal] = useState(false);

  const [popupIsOpen, setPopupIsOpen] = useState(false);
  const [openInactiveMessageModal, setOpenInactiveMessageModal] = useState(false);
  const [openConfirmSubmitModal, setOpenConfirmSubmitModal] = useState(false);

  const handleSelectedPassTypeChange = (id) => {
    setSelectedPassTypeId(id);
    
    const selected = filteredPassTypes.find((pt) => pt.id === id);
    
    const newPrice = selected?.price || selected?.Price || 0;
    
    // console.log(selected, newPrice);
    setMinRedeemAmount(newPrice * 10);
    setRedeemAmount(newPrice * 10);

    setSelectedIsBundle(selected?.Price > 0);
  };

  const handleOpenPopUpModal = () => {
    setPopupIsOpen(true);
  };

  const handleClosePopUpModal = () => {
    setPopupIsOpen(false);

    if (!user.active) {
      setOpenInactiveMessageModal(true);
      return;
    }

    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const maxRedeemAmount = Math.floor(walletData.token / minRedeemAmount) * minRedeemAmount;

  const getMarks = [
    {
      value: minRedeemAmount,
      label: `$${renderNumber(minRedeemAmount)}`,
    },
    {
      value:
        minRedeemAmount * (maxRedeemAmount / minRedeemAmount < 6 ? 2 : 1),
      label: `$${renderNumber(
        minRedeemAmount * (maxRedeemAmount / minRedeemAmount < 6 ? 2 : 1),
      )}`,
    },
    {
      value:
        Math.ceil(maxRedeemAmount / minRedeemAmount / 2)
        * minRedeemAmount,
      label: `$${renderNumber(
        Math.ceil(maxRedeemAmount / minRedeemAmount / 2) * minRedeemAmount,
      )}`,
    },
    {
      value: maxRedeemAmount,
      label: `$${renderNumber(maxRedeemAmount)}`,
    },
  ];

  useEffect(() => {
    handleCloseModal();
  }, [walletData]);

  useEffect(() => {
    if (!selectedPassTypeId) {
      handleSelectedPassTypeChange(filteredPassTypes[0]?.id);
    }
  }, [openModal]);

  useEffect(() => {
    if (!selectedPassTypeId) {
      handleSelectedPassTypeChange(filteredPassTypes[0]?.id);
    }
  }, [filteredPassTypes]);

  return (
    <>
      <Grid
        xs={12}
        item
        display="flex"
        justifyContent="center"
        marginTop="32px"
      >
        <Button
          onClick={handleOpenPopUpModal}
          size="sm"
          disabled={!selectedPassTypeId}
          bordered={walletData.token < redeemIncrements}
        >
          <span className="no-wrap">
            {t('Redeem Coin Packs')}
          </span>
        </Button>
        <Modal
          title="Redeem"
          width="550px"
          open={popupIsOpen}
          handleClose={() => { handleClosePopUpModal(); }}
        >
          <p className="mb-8 text-center">
            {t(`Redemptions of your MG Tokens can be used to buy new gamer packs and Meme Coins on the
            Solana blockchain! Packs start at 1,350 Tokens and Meme Coins start at 2,000 Tokens.
            Redeem for any Meme Coin you have won with in any of your previous games. We will send
            you $200 worth of that selected Meme Coin the Friday of the week following your redemption, 
            the price of the Meme Coin at the time it is processed. Meme Coins are transferred to the Solana
            wallet you have set up in your Gamer Wallet.`)}
          </p>
          <Button onClick={handleClosePopUpModal} bordered>
            <span className="primary-text-gradient">Close to Continue</span>
          </Button>
        </Modal>
      </Grid>
      {selectedPassTypeId && (
        <Modal
          width="600px"
          title="Redeem Coin Packs"
          open={openModal && !!selectedPassTypeId && loadedWalletData}
          handleClose={handleCloseModal}
        >
          <Typography
            className="text-center"
            sx={{ fontSize: '18px', fontWeight: 600, marginBottom: 2 }}
          >
            {'Redeem can only be performed '}
            <br />
            {`in ${renderNumber(redeemIncrements)} tokens increments`}
          </Typography>

          {walletData.token >= redeemIncrements ? (
            <>
              <Grid
                item
                xs={12}
                display="flex"
                justifyContent="center"
                paddingTop="12px"
              >
                <RadioGroup
                  onChange={(e) => {
                    handleSelectedPassTypeChange(e);
                  }}
                  value={selectedPassTypeId}
                  defaultValue={selectedPassTypeId || filteredPassTypes[0].id}
                  setErrors={() => { }}
                  name="selectedPassTypeId"
                  options={filteredPassTypes.map((p) => ({
                    label: `${p.name} ($${p?.price || p?.Price}) ${p?.price
                      ? `(${Math.floor(redeemAmount / (p.price * 10))} packs)`
                      : `(${renderNumber(p.Price * 10)} tokens)`
                      }`,
                    value: p.id,
                  }))}
                />
              </Grid>
              <Grid item sx={{ marginTop: 2 }}>
                <Grid sx={{ paddingX: '8px' }}>
                  <Slider
                    min={minRedeemAmount}
                    max={maxRedeemAmount}
                    value={redeemAmount}
                    step={minRedeemAmount}
                    getAriaValueText={() => redeemAmount}
                    valueLabelDisplay="auto"
                    onChange={(e, v) => {
                      setRedeemAmount(v);
                    }}
                    disabled={maxRedeemAmount <= minRedeemAmount || selectedIsBundle}
                    marks={getMarks}
                  />
                </Grid>
                <Button
                  onClick={() => {
                    if (walletData.token >= minRedeemAmount) {
                      setOpenConfirmSubmitModal(true);
                    }
                  }}
                  className="mt-3"
                  disabled={walletData.token < minRedeemAmount}
                >
                  <span className="no-wrap">
                    {`Redeem (${renderNumber(redeemAmount)})`}
                  </span>
                </Button>
              </Grid>
            </>
          ) : (
            <Typography
              className="text-center"
              sx={{ fontSize: '16px', fontWeight: 600, marginY: 4 }}
              color="#fe5656"
            >
              {`You need at least ${renderNumber(
                redeemIncrements,
              )} tokens to Redeem coin packs, keep playing.`}
            </Typography>
          )}
        </Modal>
      )}
      {openInactiveMessageModal && (
        <Modal
          title={t('User Account')}
          width="550px"
          open={openInactiveMessageModal}
          handleClose={() => { setOpenInactiveMessageModal(false); }}
        >
          <p className="mb-10 text-center">
            {t('You must be active to withdraw or redeem tokens. Please purchase a pack to become active!')}
          </p>
          <Button onClick={() => navigate('/packages')} bordered>
            Purchase Coins
          </Button>
        </Modal>
      )}
      {openConfirmSubmitModal && (
        <Modal
          open={openConfirmSubmitModal}
          title="Are your Sure?"
          handleClose={() => setOpenConfirmSubmitModal()}
          width="550px"
        >
          <Typography
            fontSize="16px"
            fontWeight={500}
            textAlign="center"
            marginBottom="40px"
          >
            {`Do you want to Redeem ${renderNumber(redeemAmount)} MG Tokens?`}
          </Typography>
          <Grid
            item
            xs={12}
            display="flex"
            sx={{
              justifyContent: { xs: 'center', md: 'flex-end' },
              flexDirection: { xs: 'column-reverse', md: 'row' },
              alignItems: 'center',
              gap: { xs: '16px', md: '20px' },
            }}
          >
            <Button
              onClick={() => setOpenConfirmSubmitModal(false)}
              size="sm"
              type="button"
              bordered
              style={{ width: '100%', padding: '12px', fontSize: '14px' }}
            >
              {t('No')}
            </Button>
            <Button
              onClick={() => {
                if (walletData.token >= minRedeemAmount) {
                  handleRedeemCoinPackSubmit({
                    redeemAmount,
                    passTypeId: selectedPassTypeId,
                  });
                  setOpenConfirmSubmitModal(false);
                }
              }}
              size="sm"
              type="button"
              style={{ width: '100%', padding: '12px', fontSize: '14px' }}
            >
              {t('Yes')}
            </Button>
          </Grid>
        </Modal>
      )}
    </>
  );
};

export default RedeemCoinPackModal;

import { useCallback } from 'react';
import useMemberships from '../../features/Memberships/hook';
import useProfileApi from '../../features/Profile/api';

const useSolanaPay = ({
  data, handleModalClose, renewMembership = false, payWithCard = false, 
}) => {
  const { solanaPaymentConfirmedStatus, validateSolanaPaymentPass } = useMemberships();
  const { validateSolanaPaymentMembership } = useProfileApi();

  const helioIsEnabled = process.env.REACT_APP_SOLANA_PAY_ENABLED === 'true';
  const helioNetwork = process.env.REACT_APP_SOLANA_PAY_NETWORK || 'test';
  const helioPaylinkID = process.env.REACT_APP_SOLANA_PAY_REQUEST_ID;
  const helioButtonText = payWithCard ? 'Pay with Credit Card' : process.env.REACT_APP_SOLANA_PAY_BUTTON || 'Other Payment Options';

  const paymentConfirmedStatus = async (event) => {
    await solanaPaymentConfirmedStatus({
      puid: data.PUID,
      signature: event.data.transactionSignature,
      statusToken: event.data.statusToken,
      transactionId: event.transaction,
    });

    validateSolanaPaymentPass(data.PUID);
    handleModalClose(false);
  };

  const buyMembership = async (event) => {
    await solanaPaymentConfirmedStatus({
      puid: data.PUID,
      signature: event.data.transactionSignature,
      statusToken: event.data.statusToken,
      transactionId: event.transaction,
    });

    validateSolanaPaymentMembership(data.PUID);
    handleModalClose(false);
  };

  const onSuccessHandler = useCallback(async (event) => {
    sessionStorage.setItem('_solana_pay_transaction_id', event.transaction);
    
    if (renewMembership) {
      await buyMembership(event);
    } else {
      await paymentConfirmedStatus(event);
    }
  }, [data]);

  const onErrorHandler = useCallback((event) => {
    console.log(event);
  }, [data]);

  const onPendingHandler = useCallback((event) => {
    console.log(event);
  }, [data]);

  const onCancelHandler = useCallback(() => {
    console.log('onCancelHandler---');
  }, [data]);

  const onStartPaymentHandler = useCallback(async () => {
    console.log('onStartPaymentHandler---');
  }, [data]);

  const helioConfig = {
    paylinkId: helioPaylinkID,
    amount: data?.priceTotal?.toString() || '0',
    display: 'button',
    theme: {
      themeMode: 'dark',
      colors: {
        primaryButtonBackground: 'linear-gradient(238.31deg, #78e4ff, #DE85FF)',
        primaryButtonBackgroundHover: 'linear-gradient(238.31deg, #78e4ff, #DE85FF)',
        primaryButtonText: '#000000',
        backgroundContainer: '#1b1d1f',
        shadowPrimaryGray: '#1b1d1f',
      },
    },
    onSuccess: onSuccessHandler,
    onError: onErrorHandler,
    onPending: onPendingHandler,
    onCancel: onCancelHandler,
    onStartPayment: onStartPaymentHandler,
    customTexts: {
      mainButtonTitle: helioButtonText,
    },
    network: helioNetwork,
    primaryPaymentMethod: payWithCard ? 'fiat' : 'crypto',
    showPayWithCard: payWithCard,
  };

  return {
    helioConfig,
    helioIsEnabled,
  };
};

export default useSolanaPay;
